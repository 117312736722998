<template>
  <TransitionRoot :show="mapSidePanelOpen" as="template">
    <div class="relative z-50">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="fixed inset-y-0 left-0 flex max-w-full pr-10 pointer-events-none"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="-translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="-translate-x-full"
            >
              <div
                v-on-click-outside="closePanel"
                class="pointer-events-auto w-full max-w-md transform overflow-hidden bg-panel-background text-left align-middle shadow-xl transition-all pt-safe pb-safe"
              >
                <div class="w-full h-full px-6 flex flex-col">
                  <div class="flex mt-5">
                    <ProfileUser
                      v-if="isAuthenticated"
                      class="flex-grow"
                      :profile="profile"
                      :is-quo-pro="isQuoPro"
                      :show-quo-pro-badge="true"
                    />
                    <div v-if="isAuthenticated">
                      <Menu as="div" class="relative inline-block text-left">
                        <div>
                          <MenuButton
                            class="flex items-center text-gray-500 hover:text-gray-600"
                          >
                            <span class="sr-only">Open options</span>
                            <SvgIcon
                              class="h-6 w-6"
                              name="ellipsis-vertical"
                              type="solid"
                            />
                          </MenuButton>
                        </div>

                        <transition
                          enter-active-class="transition ease-out duration-100"
                          enter-from-class="transform opacity-0 scale-95"
                          enter-to-class="transform opacity-100 scale-100"
                          leave-active-class="transition ease-in duration-75"
                          leave-from-class="transform opacity-100 scale-100"
                          leave-to-class="transform opacity-0 scale-95"
                        >
                          <MenuItems
                            class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100"
                          >
                            <div class="py-1">
                              <MenuItem v-slot="{ active }">
                                <NuxtLink
                                  :class="[
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'block px-4 py-2 text-sm',
                                  ]"
                                  :to="
                                    localePath({
                                      name: 'auth-register-create-profile',
                                    })
                                  "
                                  >{{
                                    $t('profile.menu.editProfile')
                                  }}</NuxtLink
                                >
                              </MenuItem>
                            </div>
                            <div class="py-1">
                              <MenuItem v-slot="{ active }">
                                <div
                                  href="#"
                                  class="cursor-pointer"
                                  :class="[
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'block px-4 py-2 text-sm',
                                  ]"
                                  @click="profileStore.signOut(supabase)"
                                >
                                  {{ $t('profile.menu.signOut') }}
                                </div>
                              </MenuItem>
                            </div>
                          </MenuItems>
                        </transition>
                      </Menu>
                    </div>
                  </div>
                  <div
                    v-if="showDebugInfo"
                    class="my-2 border border-gray-300 rounded-md text-xs divide-y divide-gray-300 overflow-y-auto"
                  >
                    <div class="p-2 font-bold">
                      Debug Info
                      <span
                        class="cursor-pointer"
                        @click="showDebugInfo = !showDebugInfo"
                        >Close</span
                      >
                    </div>
                    <div class="p-2">
                      user.updated_at: {{ supabaseUser?.updated_at }}
                    </div>
                    <div class="p-2">
                      profileUser: {{ supabaseUser?.updated_at }}
                    </div>
                    <div class="p-2">profile: {{ profile }}</div>
                    <div class="p-2">
                      isAuthenticated: {{ isAuthenticated }}
                    </div>
                    <div class="p-2 truncate">
                      supabase: {{ supabase.supabaseKey }}
                    </div>
                    <div class="p-2">sbEvent: {{ sbEvent }}</div>
                    <div class="p-2 truncate">sbSession: {{ sbSession }}</div>
                    <div class="p-2 max-h-20 overflow-y-auto">
                      lists: {{ lists }}
                    </div>
                    <div class="p-2">Network: {{ network }}</div>
                    <div class="p-2 flex space-x-2">
                      <div class="cursor-pointer" @click="reloadNuxtApp()">
                        reloadNuxtApp
                      </div>
                      <div
                        class="cursor-pointer"
                        @click="profileStore.signOut()"
                      >
                        Sign out
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      isQuoPro &&
                      (shareProOrigin.id === undefined ||
                        (shareProOrigin &&
                          shareProOrigin.userIdDestination === null)) &&
                      profile.userIdProShareOrigin === null
                    "
                    class="flex flex-col bg-panel-share-pro-background text-xs text-panel-share-pro-text my-6 p-3 rounded-3xl items-start justify-start"
                  >
                    <div class="flex flex-row">
                      <div><SvgIcon name="link" class="w-4 h-4 mr-1" /></div>
                      <div
                        class="space-y-1 cursor-pointer"
                        @click="startSharePro()"
                      >
                        <div>
                          <i18n-t keypath="sharePro.intro" tag="span">
                            <template v-slot:quosub>
                              <b>{{ $t('sharePro.quoProSubscription') }}</b>
                            </template> </i18n-t
                          >&nbsp;
                          <span
                            v-if="shareProOrigin.id === undefined"
                            class="underline font-bold"
                            >{{ $t('sharePro.startNowCTA') }}</span
                          >
                        </div>
                        <div
                          v-if="shareProOrigin.id !== undefined"
                          class="font-bold"
                        >
                          {{ $t('sharePro.waitingApproval') }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="shareProOpen"
                      class="flex flex-col border-t border-panel-share-pro-border mt-2 pt-3 w-full"
                    >
                      <div class="space-y-2">
                        <div class="flex flex-row">
                          <div class="">
                            <div
                              class="flex items-center justify-center bg-sky-600 rounded-ful text-white rounded-full w-5 h-5 mr-2"
                            >
                              1
                            </div>
                          </div>
                          <div class="space-y-2 w-full pt-0.5">
                            <div class="">
                              {{ $t('sharePro.sendThisLink') }}
                            </div>
                            <div class="flex flex-row space-x-2">
                              <input
                                id="shareProUrl"
                                ref="inputShareProUrl"
                                v-model="shareProUrl"
                                type="text"
                                class="rounded-full px-2 py-1 bg-panel-share-pro-input-background text-xs"
                                @focus="shareProUrlFocus()"
                              />

                              <div
                                v-if="copyIsSupported"
                                class="bg-panel-share-pro-input-background rounded-full flex items-center justify-center w-7 h-7 flex-shrink-0 cursor-pointer"
                                @click="copy()"
                              >
                                <SvgIcon
                                  v-if="!copied"
                                  name="document-duplicate"
                                  class="h-4 w-4 text-panel-share-pro-icon"
                                />
                                <SvgIcon
                                  v-else
                                  name="check"
                                  class="h-4 w-4 text-panel-share-pro-icon-check"
                                />
                              </div>
                              <div
                                v-if="shareIsSupported"
                                class="bg-panel-share-pro-input-background rounded-full flex items-center justify-center w-7 h-7 flex-shrink-0 cursor-pointer"
                                @click="share({ url: shareProUrl })"
                              >
                                <SvgIcon
                                  name="arrow-up-on-square"
                                  class="h-4 w-4 text-panel-share-pro-icon"
                                />
                              </div>
                            </div>
                            <i18n-t keypath="sharePro.expiryNote" tag="div">
                              <template v-slot:expirydate>
                                {{ shareProExpiryDate }}
                              </template>
                            </i18n-t>
                            <div>{{ $t('sharePro.notShareNote') }}</div>
                          </div>
                        </div>
                        <div class="flex flex-row">
                          <div class="">
                            <div
                              class="flex items-center justify-center bg-sky-600 rounded-ful text-white rounded-full w-5 h-5 mr-2"
                            >
                              2
                            </div>
                          </div>
                          <div class="space-y-2 w-full pt-0.5">
                            <div class="">
                              {{ $t('sharePro.stepTwo') }}
                            </div>
                          </div>
                        </div>
                        <div
                          class="flex border-t border-panel-share-pro-border !mt-3 items-center justify-center cursor-pointer pt-3 font-bold underline"
                          @click="shareProOpen = false"
                        >
                          {{ $t('sharePro.closeCTA') }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      shareProOrigin.id !== undefined &&
                      shareProOrigin.userIdDestination !== null &&
                      userDestination
                    "
                    class="flex flex-row bg-panel-share-pro-active-background text-xs text-panel-share-pro-active-text my-6 px-3 py-2 rounded-full items-start justify-start"
                  >
                    <div><SvgIcon name="link" class="w-5 h-5 mr-2" /></div>
                    <div class="pt-0.5">
                      Quo Pro shared with @{{ userDestination.username }}
                    </div>
                  </div>
                  <div
                    v-if="false"
                    class="pt-4 pb-4 sm:pb-16 text-panel-primary-text text-sm sm:text-base font-medium"
                  >
                    {{ $t('profile.inspiring') }}
                    <span class="font-bold text-panel-accent-text">13</span>
                    &middot;
                    {{ $t('profile.inspiredBy') }}
                    <span class="font-bold text-panel-accent-text">3</span>
                    &middot;
                    <span class="font-bold text-panel-send-invite-text">{{
                      $t('profile.sendInvite')
                    }}</span>
                  </div>
                  <div class="flex-1 h-px">&nbsp;</div>
                  <!-- Login -->
                  <div v-if="!isAuthenticated" class="flex flex-col">
                    <NuxtLink
                      class="w-full text-sm sm:text-base p-3 text-center align-middle bg-violet-700 text-white rounded font-medium cursor-pointer"
                      :to="localePath({ name: 'auth-sign-in' })"
                    >
                      {{ $t('general.signInCTA') }}
                    </NuxtLink>
                    <div
                      v-if="!appStore.isQuoSwift"
                      class="mt-5 mx-4 text-center text-sm text-auth-primary-text"
                    >
                      {{ $t('general.registerText') }}
                      {{ ' ' }}
                      <NuxtLink
                        class="font-semibold leading-6 text-auth-action hover:text-indigo-500"
                        :to="
                          localePath({ name: 'auth-checkout-choose-product' })
                        "
                        >{{ $t('general.registerCTA') }}&nbsp;🚀</NuxtLink
                      >
                    </div>
                    <div
                      v-else
                      class="mt-5 mx-4 text-center text-sm text-auth-primary-text"
                    >
                      <div>To create an account, visit Quo.space</div>
                      <NuxtLink
                        v-if="false"
                        class="font-semibold leading-6 text-auth-action hover:text-indigo-500"
                        to="https://quo.space/"
                        external="true"
                        >Go to Quo.space</NuxtLink
                      >
                    </div>
                  </div>
                  <!-- Lists -->
                  <div
                    v-if="isAuthenticated"
                    class="relative flex flex-col space-y-3 flex-shrink overflow-y-auto pt-4 min-h-10"
                  >
                    <div class="font-bold text-2xl pl-6 text-panel-list-title">
                      {{ $t('lists.lists') }}
                    </div>
                    <div
                      v-for="list in listStore.lists"
                      :key="list.id"
                      class="flex items-center bg-panel-list-pill-background rounded-full p-2"
                      :class="
                        isListAvailable(list.purpose, isQuoPro)
                          ? 'cursor-pointer text-panel-list-text'
                          : 'text-panel-list-text/20'
                      "
                      @click="
                        isListAvailable(list.purpose, isQuoPro) &&
                        clickList(list)
                      "
                    >
                      <div class="flex-none pr-0">
                        <SvgIcon
                          :name="list.icons.name"
                          type="outline"
                          class="w-6 h-6 ml-3 mr-4"
                        />
                      </div>
                      <div class="grow text-lg font-semibold truncate">
                        {{ getListTitle(list.name, list.purpose, t) }}
                      </div>
                      <QuoProIcon
                        v-if="!isListAvailable(list.purpose, isQuoPro)"
                        @click="clickQuoProUpgrade()"
                      />
                      <div class="flex-none">
                        <SvgIcon name="chevron-right" class="w-6 h-6" />
                      </div>
                    </div>
                    <div
                      v-if="isAuthenticated && isQuoPro"
                      class="flex justify-center rounded-full border border-panel-accent-text text-panel-accent-text py-2 cursor-pointer"
                      @click="openBottomSheetListCreate()"
                    >
                      {{ $t('bottomSheetList.createNewListCTA') }}
                    </div>
                    <div
                      v-if="isAuthenticated && !isQuoPro"
                      class="absolute h-4 bottom-0 right-0 left-0 bg-gradient-to-t from-panel-background"
                    >
                      &nbsp;
                    </div>
                  </div>
                  <!-- Quo Pro Upgrade CTA -->
                  <div
                    v-if="isAuthenticated && !isQuoPro"
                    @click="clickQuoProUpgrade()"
                  >
                    <div
                      class="flex justify-center items-center bg-panel-list-pill-background rounded-full p-2 mt-8 cursor-pointer text-panel-list-text text-lg font-semibold truncate"
                    >
                      Upgrade to <QuoProIcon class="ml-1.5" />
                    </div>
                  </div>
                  <!-- Meta -->
                  <div class="flex-1 h-px">&nbsp;</div>
                  <div
                    class="flex flex-row sm:border-b mb-3 sm:mb-6 text-panel-primary-text border-panel-hr-border"
                  >
                    <div class="basis-3/5 flex-grow flex items-center text-xs">
                      <NuxtLink :to="localePath({ name: 'support' })">
                        {{ $t('pages.support') }}</NuxtLink
                      >&nbsp;&middot;&nbsp;
                      <NuxtLink :to="localePath({ name: 'legal' })">
                        {{ $t('pages.legal') }}</NuxtLink
                      >
                      <span
                        v-if="
                          supabaseUser &&
                          supabaseUser.user_metadata &&
                          supabaseUser.user_metadata.dashboard
                        "
                        class="text-xs"
                      >
                        &nbsp;&middot;
                        <span
                          class="cursor-pointer"
                          @click="showDebugInfo = !showDebugInfo"
                          >Debug</span
                        ></span
                      >
                    </div>
                    <div
                      class="basis-1/5 flex flex-shrink justify-center items-center cursor-pointer"
                      @click="next()"
                    >
                      <div class="flex flex-row justify-center items-center">
                        <SvgIcon
                          name="globe-alt"
                          class="h-6 w-6 stroke-1.5 mr-1"
                        />
                        <span class="text-sm">{{ localeObj.shortName }}</span>
                      </div>
                    </div>
                    <div
                      class="basis-1/5 flex-shrink flex justify-center items-center cursor-pointer"
                      @click="toggleColorMode()"
                    >
                      <SvgIcon
                        :name="
                          $colorMode.preference === 'light'
                            ? 'sun'
                            : $colorMode.preference === 'dark'
                              ? 'moon'
                              : 'device-phone-mobile'
                        "
                        class="h-6 w-6 stroke-2 my-2 mr-1 ml-3"
                      />
                      {{ $colorMode.value }}
                    </div>
                  </div>
                  <!-- Footer -->
                  <div class="sm:flex items-center hidden mb-4">
                    <div>
                      <img
                        src="/logo/quo-logo-512@2x.png"
                        alt=""
                        class="w-12 h-12 rounded-lg mr-3"
                      />
                    </div>
                    <div class="font-bold text-[22px] text-panel-quo-text">
                      Quo
                    </div>
                  </div>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </div>
    </div>
  </TransitionRoot>
</template>

<script setup>
import { intlFormat } from 'date-fns'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionRoot,
  TransitionChild,
} from '@headlessui/vue'
import { useClipboard, useCycleList, useShare, useNetwork } from '@vueuse/core'
import { vOnClickOutside } from '@vueuse/components'

const { $log } = useNuxtApp()

const { t } = useI18n()
const appStore = useAppStore()
const { showDebugInfo } = storeToRefs(appStore)

const profileStore = useProfileStore()
const { isAuthenticated, isQuoPro, profile, shareProOrigin, supabaseUser } =
  storeToRefs(profileStore)

const router = useRouter()
const route = useRoute()

const controllerStore = useControllerStore()
const { mapSidePanelOpen } = storeToRefs(controllerStore)

const listStore = useListStore()
const { lists } = storeToRefs(listStore)

const { getListTitle, isListAvailable } = useList()

const network = reactive(useNetwork())

const colorModePreferences = [...controllerStore.colorModes, 'system']
$log('MapSidePanel:colorModePreferences', colorModePreferences)

const colorMode = useColorMode()

$log('MapSidePanel:colorMode.value', colorMode.value)
$log('MapSidePanel: colorMode.preference', colorMode.preference)

const toggleColorMode = () => {
  if (colorMode.preference === 'system') {
    colorMode.preference = 'light'
  } else if (colorMode.preference === 'light') {
    colorMode.preference = 'dark'
  } else {
    colorMode.preference = 'system'
  }
}

watch(
  () => colorMode.value,
  (newName, prevName) => {
    $log('MapSidePanel:watch:colorMode.value:from', prevName, 'to', newName)
  }
)

const { locale, locales } = useI18n()
const switchLocalePath = useSwitchLocalePath()
const localePath = useLocalePath()
const availableLocales = computed(() => {
  return locales.value.filter((i) => i.code !== locale.value)
})
$log('MapSidePanel:locale', locale.value)
$log('MapSidePanel:availableLocales', availableLocales.value)

const localeObj = computed(() => {
  return locales.value.find((i) => i.code === locale.value)
})

const { state, next } = useCycleList(['en', 'de', 'fr'], {
  initialValue: locale.value,
})

watch(
  () => state.value,
  (newLocale, prevLocale) => {
    $log(
      'MapSidePanel:watch:state.value:from: ',
      prevLocale,
      ' to: ',
      newLocale
    )
    $log(
      'MapSidePanel:watch:state.value:switchLocalePath:',
      switchLocalePath(newLocale)
    )
    return navigateTo(switchLocalePath(newLocale))
    // The log after navigation won't be reached in this function
    // $log('MapSidePanel:watch:state.value:switch done')
  }
)

const clickQuoProUpgrade = () => {
  return navigateTo(
    localePath({
      name: 'auth-checkout-choose-product',
      query: { chosenproduct: 'quo-pro', redirect: null },
    })
  )
}

const clickList = (list) => {
  $log('MapSidePanel:clickList:list:', list)
  controllerStore.closeMapSidePanel()
  return navigateTo(
    localePath({
      name: 'map-list-nanoId-slug',
      params: { nanoId: list.nanoId },
    })
  )
}

const openBottomSheetListCreate = () => {
  controllerStore.openBottomSheetListCreate()
}

const shareProExpiryDate = computed(() => {
  if (shareProOrigin.value.expiredAt !== undefined) {
    return intlFormat(
      new Date(shareProOrigin.value.expiredAt),
      {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
      { locale: locale.value }
    )
  }
  return ''
})

const config = useRuntimeConfig()

const shareProUrl = computed(() => {
  if (shareProOrigin.value.sharecodeNanoId !== undefined) {
    return `${config.public.siteRootUrl}/auth/invite/share-pro?code=${shareProOrigin.value.sharecodeNanoId}`
  }
  return ''
})

$log('MapSidePanel:shareProOrigin:', shareProOrigin.value)

const shareProOpen = ref(false)
const {
  copy,
  copied,
  isSupported: copyIsSupported,
} = useClipboard({ source: shareProUrl })
const { share, isSupported: shareIsSupported } = useShare()

const inputShareProUrl = ref(null)
const shareProUrlFocus = () => {
  $log('MapSidePanel:shareProUrlFocus')
  inputShareProUrl.value.select()
  inputShareProUrl.value.setSelectionRange(0, 99999)
}

const startSharePro = async () => {
  $log('MapSidePanel:startSharing')
  shareProOpen.value = true
  const shareProOriginData = await profileStore.createShareProOrigin()
  $log('MapSidePanel:startSharing:shareProOriginData: ', shareProOriginData)
}

const userDestination = computed(() => {
  if (shareProOrigin.value.userIdDestination !== null) {
    return profileStore.getProfileByUserId(
      shareProOrigin.value.userIdDestination
    )
  }
  return null
})

const closePanel = () => {
  $log('MapSidePanel.vue:closePanel')
  const { sidepanel, ...queryWithoutSidepanel } = route.query
  router.push({
    path: route.path,
    query: queryWithoutSidepanel,
  })
}

const supabase = useSupabaseClient()

const sbEvent = ref()
const sbSession = ref()

supabase.auth.onAuthStateChange((event, session) => {
  sbEvent.value = event
  sbSession.value = session
  $log('MapSidePanel:onAuthStateChange', event)
})
</script>
