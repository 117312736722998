<template>
  <div
    ref="guideDetailTextSheet"
    v-scroll="onScroll"
    class="mx-2 sm:mx-4 bg-panel-background rounded-t-2xl overflow-x-hidden pointer-events-auto sm:h-full overscroll-y-none"
    :style="xs ? 'height: ' + height + 'px;' : ''"
    :class="isDragging ? 'overflow-y-hidden' : 'overflow-y-auto'"
  >
    <div class="sticky top-0 z-10">
      <div class="relative bg-panel-background/90">
        <img
          v-if="guideMediumUrl"
          :src="guideMediumUrl"
          alt=""
          class="w-full object-cover pointer-events-none"
          :class="currentPosition === 'bottom' ? 'h-32' : 'h-48'"
        />
        <div v-else class="h-10">&nbsp;</div>
        <div
          ref="guideDetailTextMoveHandle"
          class="absolute w-full h-full top-0"
        ></div>
        <div
          class="absolute w-full h-full top-0 grid grid-cols-1 gap-4 content-between pointer-events-none p-1"
        >
          <div class="flex flex-row justify-between">
            <div
              v-if="
                selectedItemId ||
                selectedListId ||
                showFeedResult ||
                searchText ||
                selectedHighlightId ||
                selectedGuideNanoIdUnsecure
              "
              class="p-2 pointer-events-auto"
            >
              <button
                type="button"
                :class="[
                  guideMediumUrl
                    ? 'bg-panel-nav-control-background-medium'
                    : 'bg-panel-nav-control-background',
                  ' w-8 h-8 rounded-lg bg-opacity-60 pointer-events-auto',
                ]"
                @click="$router.go(-1)"
              >
                <SvgIcon
                  name="chevron-left"
                  :class="[
                    guideMediumUrl
                      ? 'stroke-panel-nav-control-action-symbol-medium'
                      : 'stroke-panel-nav-control-action-symbol',
                    'h-5 w-5 my-auto mx-auto',
                  ]"
                />
              </button>
            </div>
            <div v-else class="p-2 w-8">&nbsp;</div>
            <div class="p-2">
              <div
                :class="[
                  guideMediumUrl
                    ? 'border-panel-nav-control-background-medium'
                    : 'border-panel-nav-control-background',
                  'w-12 h-0 border-2  bg-opacity-60 rounded sm:hidden',
                ]"
              >
                &nbsp;
              </div>
            </div>
            <div class="p-2">
              <button
                type="button"
                :class="[
                  guideMediumUrl
                    ? 'bg-panel-nav-control-background-medium'
                    : 'bg-panel-nav-control-background',
                  'w-8 h-8 rounded-lg bg-opacity-60 pointer-events-auto',
                ]"
                @click="close()"
              >
                <SvgIcon
                  name="x-mark"
                  :class="[
                    guideMediumUrl
                      ? 'stroke-panel-nav-control-action-symbol-medium'
                      : 'stroke-panel-nav-control-action-symbol',
                    'h-5 w-5 my-auto mx-auto',
                  ]"
                />
              </button>
            </div>
          </div>
          <div v-if="guideMediumUrl" class="flex flex-row justify-between">
            <div class="p-2">
              <span
                v-if="showTitleOverImage"
                class="text-white text-2xl font-bold drop-shadow-[0_1.4px_1.4px_rgba(0,0,0,0.8)]"
                >{{ guideTitle }} {{ selectedGuideDetailKeyInsecure }}</span
              >
            </div>
            <div class="p-2">
              <button
                v-if="false"
                type="button"
                :class="[
                  guideMediumUrl
                    ? 'bg-panel-nav-control-background-medium'
                    : 'bg-panel-nav-control-background',
                  'w-8 h-8 rounded-lg bg-opacity-60 pointer-events-auto',
                ]"
              >
                <SvgIcon
                  name="photo"
                  :class="[
                    guideMediumUrl
                      ? 'stroke-panel-nav-control-action-symbol-medium'
                      : 'stroke-panel-nav-control-action-symbol',
                    'h-5 w-5 my-auto mx-auto',
                  ]"
                />
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="!guideMediumUrl"
          class="text-panel-accent-text text-2xl font-semibold px-3 pt-4 pb-3"
        >
          <h1>{{ guideTitle }} {{ selectedGuideDetailKeyInsecure }}</h1>
        </div>
      </div>
    </div>
    <div
      v-if="guideMediumUrl"
      class="text-panel-accent-text text-2xl font-semibold px-3 pt-4 pb-3"
    >
      <h1>{{ guideTitle }} {{ selectedGuideDetailKeyInsecure }}</h1>
    </div>

    <div
      class="px-4 py-4 text-panel-accent-text whitespace-pre-line text-sm font-light leading-tight"
    >
      {{ detailValue }}
    </div>

    <div v-if="false" class="px-4 text-xs py-4">
      {{ baseRouteName }}
      {{ selectedGuideDetailKeyInsecure }}
      {{ currentPosition }}
      {{ guideDetailSheetPosition }}
      {{ pending }}
      {{ selectedGuideNanoIdUnsecure }}
      {{ selectedGuide }}
    </div>

    <!-- Footer -->
    <div class="px-3 py-10">
      <div class="text-sm text-center text-slate-400">&copy; Quo Space</div>
    </div>
  </div>
</template>
<script setup>
import { useGesture } from '@vueuse/gesture'
import { useRound } from '@vueuse/math'
import {
  breakpointsTailwind,
  useBreakpoints,
  useShare,
  useWindowSize,
} from '@vueuse/core'
import { vScroll } from '@vueuse/components'

const { $log, $metric } = useNuxtApp()
$log('components:GuideDetail:setup:begin')

const { locale, t } = useI18n()
const localePath = useLocalePath()

const route = useRoute()
$log('components:MapContent:route', route)

const profileStore = useProfileStore()
const { isAuthenticated, isQuoPro } = storeToRefs(profileStore)

const breakpoints = useBreakpoints(breakpointsTailwind)
const xs = breakpoints.smaller('sm')

const itemStore = useItemStore()
const { selectedItemId } = storeToRefs(itemStore)

const listStore = useListStore()
const { selectedListId } = storeToRefs(listStore)

const feedStore = useFeedStore()
const { showFeedResult } = storeToRefs(feedStore)

const currentPosition = ref(null)

const controllerStore = useControllerStore()
const {
  guideDetailSheetPosition,
  snapBottomPosition,
  snapMiddlePosition,
  snapTopPosition,
} = storeToRefs(controllerStore)

const searchStore = useSearchStore()
const { searchText, selectedHighlightId } = storeToRefs(searchStore)

const user = useSupabaseUser()

const { closeSelectedGuide } = useController()

const guideStore = useGuideStore()
const { selectedGuideNanoIdUnsecure, selectedGuideDetailKeyInsecure } =
  storeToRefs(guideStore)

const { pending, data: selectedGuide } = await useAsyncData(
  async () => {
    const returnValue = await guideStore.fetchGuideByNanoId(
      selectedGuideNanoIdUnsecure.value
    )
    return returnValue
  },
  { lazy: true, watch: selectedGuideNanoIdUnsecure }
)

const { getGuideTitle, getGuideMediumUrl } = useGuide()

const guideTitle = computed(() =>
  getGuideTitle(selectedGuide.value, locale.value)
)

const guideMediumUrl = computed(() => {
  if (!selectedGuide.value) return null
  const url = getGuideMediumUrl(selectedGuide.value.media[0])
  if (url !== null) return url
  return null
})

const { currentRoute } = useRouter()

const getRouteBaseName = useRouteBaseName()

// const getRouteBaseName = useRouteBaseName()
const baseRouteName = computed(() => {
  return getRouteBaseName(route)
})

$log('components: Text: currentRoute', currentRoute.value)

const detailValue = computed(() => {
  if (
    selectedGuideDetailKeyInsecure.value &&
    selectedGuide.value &&
    selectedGuide.value.details
  ) {
    const detail = selectedGuide.value.details.filter(
      (detail) => detail.key === selectedGuideDetailKeyInsecure.value
    )
    if (detail.length > 0) {
      return detail[0].value
    }
  }
  return null
})

const guideDetailTextMoveHandle = ref()
const height = ref(0)
const guideDetailTextSheet = ref()
const showTitleOverImage = ref(false)

const { height: windowHeight } = useWindowSize()

function onScroll(state) {
  const { y } = state
  const newDevInfo = {
    guideDetailTextScrollY: y,
  }
  controllerStore.addDevInfo(newDevInfo)
  if (y.value > 28) {
    showTitleOverImage.value = true
  } else {
    showTitleOverImage.value = false
  }
}

const gestureModule = useGesture(
  {
    onDrag: handleSurfaceDrag,
  },
  {
    domTarget: guideDetailTextMoveHandle,
    eventOptions: { passive: true },
    drag: {
      filterTaps: true,
    },
  }
)

const isDragging = ref(false)

function handleSurfaceDrag({ movement: [x, y], dragging, swipe }) {
  height.value =
    windowHeight.value - guideDetailSheetPosition.value - useRound(y).value
  isDragging.value = dragging
  // const offset = sheet.value.getBoundingClientRect()
  const close = findClosestSnapPoint(
    guideDetailSheetPosition.value + useRound(y).value
  )

  if (swipe[1] !== 0) {
    const next = findNextSnapPoint(
      guideDetailSheetPosition.value + useRound(y).value,
      swipe[1]
    )
    height.value = windowHeight.value - next
  }
  if (!dragging) {
    guideDetailSheetPosition.value = close
    height.value = windowHeight.value - close
    return
  }

  // update initial point for next drag
  gestureModule.config.drag.initial = [x, 0]
}

// find closest snap point
const findClosestSnapPoint = (currentYPosition) => {
  const positions = []
  positions.push(snapTopPosition.value)
  positions.push(snapMiddlePosition.value)
  positions.push(snapBottomPosition.value)

  const closest = positions.reduce(function (prev, curr) {
    return Math.abs(curr - currentYPosition) < Math.abs(prev - currentYPosition)
      ? curr
      : prev
  })

  return closest
}

// find next snap point
const findNextSnapPoint = (currentYPosition, swipeDirection) => {
  const positions = []
  positions.push(snapTopPosition.value)
  positions.push(snapMiddlePosition.value)
  positions.push(snapBottomPosition.value)

  const next = positions.reduce(function (prev, curr) {
    if (swipeDirection === -1) {
      return curr < currentYPosition ? curr : prev
    } else {
      return curr > currentYPosition ? curr : prev
    }
  })

  return next
}

const close = async () => {
  if (searchText) {
    searchText.value = null
  }
  await closeSelectedGuide()
}

watch(
  selectedGuide,
  async () => {
    if (selectedGuide.value && import.meta.client) {
      $log('components: GuideDetailText: selectedGuide changed')
      // await $metric({ key: 'PAGE_VIEW', itemId: selectedItem.value.id })
    }
  },
  { immediate: true }
)

watch(
  guideDetailSheetPosition,
  () => {
    // assign current position to currentPosition by comparing guideDetailSheetPosition to snapTopPosition, snapMiddlePosition, snapBottomPosition. Assign top, middle, bottom.
    if (guideDetailSheetPosition.value === snapTopPosition.value) {
      currentPosition.value = 'top'
    } else if (guideDetailSheetPosition.value === snapMiddlePosition.value) {
      currentPosition.value = 'middle'
    } else if (guideDetailSheetPosition.value === snapBottomPosition.value) {
      currentPosition.value = 'bottom'
    } else {
      currentPosition.value = null
    }
  },
  { immediate: true }
)

onBeforeMount(() => {
  $log('components: GuideDetailText: onBeforeMount')
})

onMounted(() => {
  $log('components: GuideDetailText: onMounted')
  $log(
    'components: GuideDetailText: guideDetailSheetPosition',
    guideDetailSheetPosition.value
  )
  if (guideDetailSheetPosition.value === 0) {
    guideDetailSheetPosition.value = snapMiddlePosition.value
  }
  height.value = windowHeight.value - guideDetailSheetPosition.value
})

onUpdated(() => {
  $log('components: GuideDetailText: onUpdated')
})

onUnmounted(() => {
  $log('components: GuideDetailText: onUnmounted')
})

$log('components: GuideDetailText:setup:end')
</script>
