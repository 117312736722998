<template>
  <div
    ref="guideDetailSheet"
    v-scroll="onScroll"
    class="mx-2 sm:mx-4 bg-panel-background rounded-t-2xl overflow-x-hidden pointer-events-auto sm:h-full overscroll-y-none"
    :style="xs ? 'height: ' + height + 'px;' : ''"
    :class="isDragging ? 'overflow-y-hidden' : 'overflow-y-auto'"
  >
    <div class="sticky top-0 left-0 right-0 z-10 bg-panel-background">
      <div class="relative bg-panel-background/90">
        <img
          v-if="guideMediumUrl"
          :src="guideMediumUrl"
          alt=""
          class="w-full object-cover pointer-events-none"
          :class="
            currentPosition === 'bottom'
              ? 'h-32'
              : showMinimalHeader
                ? 'h-32'
                : 'h-48'
          "
          style="
            transition:
              height 0.33s ease,
              background-color 0.3s ease;
          "
        />
        <div v-else class="h-10">&nbsp;</div>
        <div
          ref="guideDetailMoveHandle"
          class="absolute w-full h-full top-0"
        ></div>
        <div
          class="absolute w-full h-full top-0 grid grid-cols-1 gap-4 content-between pointer-events-none p-1"
        >
          <div class="flex flex-row justify-between">
            <div
              v-if="
                selectedItemId ||
                selectedListId ||
                showFeedResult ||
                searchText ||
                selectedHighlightId
              "
              class="p-2 pointer-events-auto"
            >
              <button
                type="button"
                :class="[
                  guideMediumUrl
                    ? 'bg-panel-nav-control-background-medium'
                    : 'bg-panel-nav-control-background',
                  ' w-8 h-8 rounded-lg bg-opacity-60 pointer-events-auto',
                ]"
                @click="$router.go(-1)"
              >
                <SvgIcon
                  name="chevron-left"
                  :class="[
                    guideMediumUrl
                      ? 'stroke-panel-nav-control-action-symbol-medium'
                      : 'stroke-panel-nav-control-action-symbol',
                    'h-5 w-5 my-auto mx-auto',
                  ]"
                />
              </button>
            </div>
            <div v-else class="p-2 w-8">&nbsp;</div>
            <div class="p-2">
              <div
                :class="[
                  guideMediumUrl
                    ? 'border-panel-nav-control-background-medium'
                    : 'border-panel-nav-control-background',
                  'w-12 h-0 border-2 bg-opacity-60 rounded sm:hidden',
                ]"
              >
                &nbsp;
              </div>
            </div>
            <div class="p-2">
              <button
                type="button"
                :class="[
                  guideMediumUrl
                    ? 'bg-panel-nav-control-background-medium'
                    : 'bg-panel-nav-control-background',
                  'w-8 h-8 rounded-lg bg-opacity-60 pointer-events-auto',
                ]"
                @click="close()"
              >
                <SvgIcon
                  name="x-mark"
                  :class="[
                    guideMediumUrl
                      ? 'stroke-panel-nav-control-action-symbol-medium'
                      : 'stroke-panel-nav-control-action-symbol',
                    'h-5 w-5 my-auto mx-auto',
                  ]"
                />
              </button>
            </div>
          </div>
          <div v-if="guideMediumUrl" class="flex flex-row justify-between">
            <div class="p-2">
              <span
                v-if="showTitleOverImage"
                class="text-white text-2xl font-bold drop-shadow-[0_1.4px_1.4px_rgba(0,0,0,0.8)]"
                >{{ guideTitle }}</span
              >
            </div>
            <div class="p-2">
              <button
                v-if="false"
                type="button"
                :class="[
                  guideMediumUrl
                    ? 'bg-panel-nav-control-background-medium'
                    : 'bg-panel-nav-control-background',
                  'w-8 h-8 rounded-lg bg-opacity-60 pointer-events-auto',
                ]"
              >
                <SvgIcon
                  name="photo"
                  :class="[
                    guideMediumUrl
                      ? 'stroke-panel-nav-control-action-symbol-medium'
                      : 'stroke-panel-nav-control-action-symbol',
                    'h-5 w-5 my-auto mx-auto',
                  ]"
                />
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="!guideMediumUrl"
          class="text-panel-accent-text text-2xl font-semibold px-3 pt-4 pb-3"
        >
          <h1>{{ guideTitle }}</h1>
        </div>
      </div>
    </div>
    <div
      v-if="guideMediumUrl"
      class="text-panel-accent-text text-2xl font-semibold px-3 pt-4 pb-3"
    >
      <h1>{{ guideTitle }}</h1>
    </div>
    <div class="px-3 pb-3 text-panel-secondary-text text-sm font-normal">
      <span v-for="(address, index) in guideAddresses" :key="index">
        <span v-if="index > 0"> &middot; </span>
        <NuxtLink
          :to="
            localePath({
              name: 'map-guide-nanoId-title-key',
              params: {
                nanoId: address.guideNanoId,
                title: slugify(address.nameEn),
              },
            })
          "
        >
          {{ address.nameEn }}
        </NuxtLink>
      </span>
      <span v-if="population">
        <span v-if="guideAddresses.length > 0"> &middot; </span>
        {{ population }}
      </span>
    </div>
    <div
      class="px-3 pb-3 text-sm font-normal text-panel-accent-text leading-tight tracking-tight"
    >
      {{ guideShortDescription }}
    </div>
    <div
      v-if="nonZeroStats.length > 0"
      class="mx-3 py-3 text-panel-primary-text text-sm font-medium border-y border-panel-hr-border"
    >
      <span v-for="(stat, index) in nonZeroStats" :key="index">
        <span v-if="index > 0"> &middot; </span>
        <span class="font-bold text-panel-accent-text">{{ stat.count }}</span>
        {{ stat.name }}
      </span>
    </div>
    <div
      v-if="guideLists && guideLists.length > 0"
      class="text-panel-accent-text text-xl font-semibold px-3 pt-4 pb-3"
    >
      Lists
    </div>
    <div
      v-if="guideLists && guideLists.length > 0"
      class="grid grid-cols-3 gap-3 px-3"
    >
      <NuxtLink
        v-for="list in guideLists"
        :key="list.nanoId"
        class="relative rounded-lg aspect-square overflow-hidden"
        :class="
          getTileColorName(list)
            ? 'bg-guide-' + getTileColorName(list) + '-background'
            : 'bg-gray-300'
        "
        :to="
          localePath({
            name: 'map-list-nanoId-slug',
            params: { nanoId: list.nanoId },
          })
        "
      >
        <img
          v-if="tileImagePath(list)"
          :src="getPublicUrl(tileImagePath(list))"
          class="object-cover w-full h-full"
        />
        <div
          class="absolute w-full top-0 p-2 font-semibold text-panel-accent-text text-nowrap"
        >
          {{ list.name }}
        </div>
      </NuxtLink>
    </div>
    <!-- About -->
    <div
      v-if="historyTeaser || tippingTeaser"
      class="text-panel-accent-text text-xl font-semibold px-3 pt-8 pb-3"
    >
      About {{ guideTitle }}
    </div>
    <ul
      role="list"
      class="divide-y divide-guide-card-divide overflow-hidden bg-guide-card-background shadow-sm ring-1 ring-guide-card-ring ring-opacity-5 sm:rounded-xl mx-3"
    >
      <NuxtLink
        is="li"
        v-if="historyTeaser"
        :to="
          localePath({
            name: 'map-guide-nanoId-title-key',
            params: {
              nanoId: selectedGuideNanoIdUnsecure,
              title: selectedGuide.title,
              key: 'history',
            },
          })
        "
        class="relative flex justify-between gap-x-3 px-3 py-3 sm:px-3"
      >
        <div class="flex min-w-0 gap-x-3">
          <div class="min-w-0 flex-auto">
            <p class="text-sm font-semibold leading-6 text-guide-card-title">
              History
            </p>
            <p
              class="mt-1 text-xs text-guide-card-text line-clamp-2 leading-tight"
            >
              {{ historyTeaser }}
            </p>
          </div>
        </div>
        <div class="flex shrink-0 items-center gap-x-4">
          <SvgIcon
            name="chevron-right"
            class="h-5 w-5 flex-none text-guide-card-chevron"
          />
        </div>
      </NuxtLink>
      <NuxtLink
        is="li"
        v-if="tippingTeaser"
        :to="
          localePath({
            name: 'map-guide-nanoId-title-key',
            params: {
              nanoId: selectedGuideNanoIdUnsecure,
              title: selectedGuide.title,
              key: 'tipping',
            },
          })
        "
        class="relative flex justify-between gap-x-3 px-3 py-3 sm:px-3"
      >
        <div class="flex min-w-0 gap-x-3">
          <div class="min-w-0 flex-auto">
            <p class="text-sm font-semibold leading-6 text-guide-card-title">
              <span class="absolute inset-x-0 -top-px bottom-0" />
              Tipping
            </p>
            <p
              class="t-1 text-xs text-guide-card-text line-clamp-2 leading-tight"
            >
              {{ tippingTeaser }}
            </p>
          </div>
        </div>
        <div class="flex shrink-0 items-center gap-x-4">
          <SvgIcon
            name="chevron-right"
            class="h-5 w-5 flex-none text-guide-card-chevron"
          />
        </div>
      </NuxtLink>
    </ul>
    <div v-if="countryGuide">
      <div class="text-panel-accent-text text-xl font-semibold px-3 pt-8 pb-3">
        About {{ countryGuideTitle }}
      </div>
      <ul
        role="list"
        class="divide-y divide-guide-card-divide overflow-hidden bg-guide-card-background shadow-sm ring-1 ring-guide-card-ring ring-opacity-5 sm:rounded-xl mx-3"
      >
        <NuxtLink
          is="li"
          v-if="countryGuideHistory"
          :to="
            localePath({
              name: 'map-guide-nanoId-title-key',
              params: {
                nanoId: countryGuide.nanoId,
                title: countryGuideTitle,
                key: 'history',
              },
            })
          "
          class="relative flex justify-between gap-x-3 px-3 py-3 sm:px-3"
        >
          <div class="flex min-w-0 gap-x-3">
            <div class="min-w-0 flex-auto">
              <p class="text-sm font-semibold leading-6 text-guide-card-title">
                <span class="absolute inset-x-0 -top-px bottom-0" />
                History
              </p>
              <p
                class="mt-1 text-xs text-guide-card-text line-clamp-2 leading-tight"
              >
                {{ countryGuideHistory }}
              </p>
            </div>
          </div>
          <div class="flex shrink-0 items-center gap-x-4">
            <SvgIcon
              name="chevron-right"
              class="h-5 w-5 flex-none text-guide-card-chevron"
            />
          </div>
        </NuxtLink>
        <NuxtLink
          is="li"
          v-if="countryGuideTipping"
          :to="
            localePath({
              name: 'map-guide-nanoId-title-key',
              params: {
                nanoId: countryGuide.nanoId,
                title: slugify(countryGuideTitle),
                key: 'tipping',
              },
            })
          "
          class="relative flex justify-between gap-x-3 px-3 py-3 sm:px-3"
        >
          <div class="flex min-w-0 gap-x-3">
            <div class="min-w-0 flex-auto">
              <p class="text-sm font-semibold leading-6 text-guide-card-title">
                <span class="absolute inset-x-0 -top-px bottom-0" />
                Tipping
              </p>
              <p
                class="mt-1 text-xs text-guide-card-text line-clamp-2 leading-tight"
              >
                {{ countryGuideTipping }}
              </p>
            </div>
          </div>
          <div class="flex shrink-0 items-center gap-x-4">
            <SvgIcon
              name="chevron-right"
              class="h-5 w-5 flex-none text-guide-card-chevron"
            />
          </div>
        </NuxtLink>
      </ul>
    </div>

    <div
      v-if="false"
      class="px-4 text-xs py-4 flex flex-col divide-y space-y-4 dark:text-white"
    >
      <div>{{ baseRouteName }}</div>
      <div>{{ currentPosition }}</div>
      <div>{{ guideDetailSheetPosition }}</div>
      <div>{{ status }}</div>
      <div>{{ selectedGuideNanoIdUnsecure }}</div>
      <div>
        <pre>{{ selectedGuide }}</pre>
      </div>
      <div>countryDetails: {{ countryDetails }}</div>
      <div>countryStatus : {{ countryStatus }}</div>
      <div>countryGuide: {{ countryGuide }}</div>
    </div>

    <!-- Footer -->
    <div class="px-3 py-10">
      <div class="text-sm text-center text-slate-400">&copy; Quo Space</div>
    </div>
  </div>
</template>
<script setup>
import { useGesture } from '@vueuse/gesture'
import { useRound } from '@vueuse/math'
import {
  breakpointsTailwind,
  useBreakpoints,
  useSorted,
  useWindowSize,
} from '@vueuse/core'
import { vScroll } from '@vueuse/components'

const { $log, $metric } = useNuxtApp()
$log('components:GuideDetail:setup:begin')

const { locale } = useI18n()
const { slugify } = useSlugify()
const { setOpenGraph } = useOpenGraph()
const localePath = useLocalePath()

const route = useRoute()

const getRouteBaseName = useRouteBaseName()

const baseRouteName = computed(() => {
  return getRouteBaseName(route)
})

const profileStore = useProfileStore()
const { isAuthenticated, isQuoPro } = storeToRefs(profileStore)

const breakpoints = useBreakpoints(breakpointsTailwind)
const xs = breakpoints.smaller('sm')

const itemStore = useItemStore()
const { selectedItemId } = storeToRefs(itemStore)

const listStore = useListStore()
const { selectedListId } = storeToRefs(listStore)

const feedStore = useFeedStore()
const { showFeedResult } = storeToRefs(feedStore)

const currentPosition = ref(null)

const controllerStore = useControllerStore()
const {
  guideDetailSheetPosition,
  snapBottomPosition,
  snapMiddlePosition,
  snapTopPosition,
} = storeToRefs(controllerStore)

const searchStore = useSearchStore()
const { searchText, selectedHighlightId } = storeToRefs(searchStore)

const supabase = useSupabaseClient()
const user = useSupabaseUser()

const { closeSelectedGuide } = useController()

const guideStore = useGuideStore()
const { selectedGuideNanoIdUnsecure } = storeToRefs(guideStore)

onBeforeMount(() => {
  $log('components: GuideDetail: onBeforeMount')
})

onMounted(() => {
  $log('components: GuideDetail: onMounted')
  $log(
    'components: GuideDetail: guideDetailSheetPosition',
    guideDetailSheetPosition.value
  )
  if (guideDetailSheetPosition.value === 0) {
    guideDetailSheetPosition.value = snapMiddlePosition.value
  }
  height.value = windowHeight.value - guideDetailSheetPosition.value
})

onUpdated(() => {
  $log('components: GuideDetail: onUpdated')
})

onUnmounted(() => {
  $log('components: GuideDetail: onUnmounted')
})

// MARK: first await
const { data: selectedGuide, status } = await useAsyncData(
  async () => {
    if (!selectedGuideNanoIdUnsecure.value) return null
    const returnValue = await guideStore.fetchGuideByNanoId(
      selectedGuideNanoIdUnsecure.value
    )
    return returnValue
  },
  {
    lazy: import.meta.client, // Lazy on client-side, immediate on server-side
    server: true, // Ensure it runs on the server
    watch: selectedGuideNanoIdUnsecure,
  }
)

const historyTeaser = computed(() => {
  const histories = selectedGuide.value?.details?.filter(
    (detail) => detail.key === 'history'
  )
  return histories?.length ? histories[0].value : null
})

const tippingTeaser = computed(() => {
  const tippings = selectedGuide.value?.details?.filter(
    (detail) => detail.key === 'tipping'
  )
  return tippings?.length ? tippings[0].value : null
})

const { getGuideShortDescription, getGuideTitle, getGuideMediumUrl } =
  useGuide()

const guideShortDescription = computed(() =>
  getGuideShortDescription(selectedGuide.value, locale.value)
)

const guideTitle = computed(() =>
  getGuideTitle(selectedGuide.value, locale.value)
)

const guideMediumUrl = computed(() => {
  if (!selectedGuide.value) return null
  const url = getGuideMediumUrl(selectedGuide.value.media[0])
  if (url !== null) return url
  return null
})

const getAddressByType = (type) => {
  if (!type) return null
  if (!selectedGuide.value.addresses) return null
  const address = selectedGuide.value.addresses.find(
    (address) => address.type === type
  )
  if (!address) return null

  return address
}

const guideAddresses = computed(() => {
  if (!selectedGuide.value) return []
  if (!selectedGuide.value.addresses) return []
  const sortedAddresses = []

  const city = getAddressByType('city')
  if (city && selectedGuide.value.id !== city.guideId) {
    sortedAddresses.push(city)
  }

  const state = getAddressByType('state')
  if (state && selectedGuide.value.id !== state.guideId) {
    sortedAddresses.push(state)
  }

  const country = getAddressByType('country')
  if (country && selectedGuide.value.id !== country.guideId) {
    sortedAddresses.push(country)
  }

  return sortedAddresses
})

const population = computed(() => {
  const details = selectedGuide.value?.details
  if (!details?.length) return null

  const populationDetail = details.find((detail) => detail.key === 'population')
  if (!populationDetail) return null

  const value = Number(populationDetail.value)
  if (isNaN(value)) return `Pop ${populationDetail.value}`

  if (value >= 1_000_000) {
    return `Pop ${(Math.round(value / 100_000) / 10).toFixed(1)} Million`
  }

  if (value >= 1_000) {
    return `Pop ${(Math.round(value / 100) / 10).toFixed(1)} Thousand`
  }

  return `Pop ${value}`
})

const guideLists = computed(() => {
  if (!selectedGuide.value) return []

  if (selectedGuide.value.lists) {
    const sortedList = useSorted(selectedGuide.value.lists, (a, b) => {
      return a.sortOrder - b.sortOrder
    })
    $log('components: GuideDetail: guideLists', sortedList.value)
    return sortedList.value
  }

  return []
})

const getTileColorName = (list) => {
  if (!list) return null
  if (list.tileColorName) {
    return list.tileColorName
  } else if (list.defaultTileColorName) {
    return list.defaultTileColorName
  }
  return null
}

const tileImagePath = (list) => {
  if (!list) return null
  if (list.tileImagePath) {
    return list.tileImagePath
  } else if (list.defaultTileImagePath) {
    return list.defaultTileImagePath
  }
  return null
}

const getPublicUrl = (path) => {
  const { data } = supabase.storage.from('public-media').getPublicUrl(path, {
    transform: {
      width: 256,
      height: 256,
    },
  })
  return data.publicUrl
}

const stats = computed(() => {
  const newStats = []
  return newStats
})

const nonZeroStats = computed(() => {
  return stats.value.filter((stat) => stat.count > 0)
})

const countryDetails = computed(() => {
  if (!selectedGuide.value) return null
  if (selectedGuide.value.type !== 'country') {
    const country = selectedGuide.value.addresses.find(
      (address) => address.type === 'country'
    )
    if (country) {
      return country
    }
  }
  return null
})

const countryNanoId = computed(() => {
  if (!countryDetails.value) return null
  if (countryDetails.value.guideNanoId) {
    return countryDetails.value.guideNanoId
  }
  return null
})

const { data: countryGuide, status: countryStatus } = await useAsyncData(
  async () => {
    if (!countryNanoId.value) return null
    const returnValue = await guideStore.fetchGuideByNanoId(countryNanoId.value)
    return returnValue
  },
  { lazy: true, watch: countryNanoId }
)

const countryGuideTitle = computed(() => {
  if (!countryGuide.value) return null
  return getGuideTitle(countryGuide.value, locale.value)
})

const countryGuideHistory = computed(() => {
  if (!countryGuide.value) return null
  if (countryGuide.value.details && countryGuide.value.details.length > 0) {
    const histories = countryGuide.value.details.filter(
      (detail) => detail.key === 'history'
    )
    if (histories) {
      return histories[0].value
    }
  }
  return null
})

const countryGuideTipping = computed(() => {
  if (!countryGuide.value) return null
  if (countryGuide.value.details && countryGuide.value.details.length > 0) {
    const tipping = countryGuide.value.details.filter(
      (detail) => detail.key === 'tipping'
    )
    if (tipping) {
      return tipping[0].value
    }
  }
  return null
})

const { currentRoute } = useRouter()

const guideDetailMoveHandle = ref()
const height = ref(0)
const guideDetailSheet = ref()
const showTitleOverImage = ref(false)
const showMinimalHeader = ref(false)

const { height: windowHeight } = useWindowSize()

// Add this new method
const scrollTo = (y, behavior = 'smooth') => {
  if (guideDetailSheet.value) {
    guideDetailSheet.value.scrollTo({
      top: y,
      behavior: behavior, // 'smooth' or 'auto'
    })
  }
}

function onScroll(state) {
  const { y } = state
  const newDevInfo = {
    guideScrollY: y,
  }
  controllerStore.addDevInfo(newDevInfo)
  if (y.value > 28) {
    showTitleOverImage.value = true
  } else {
    showTitleOverImage.value = false
  }
  if (y.value > 28 && showMinimalHeader.value === false) {
    showMinimalHeader.value = true
    scrollTo(64)
  } else if (y.value < 8 && showMinimalHeader.value === true) {
    showMinimalHeader.value = false
    scrollTo(0)
  }
}

const gestureModule = useGesture(
  {
    onDrag: handleSurfaceDrag,
  },
  {
    domTarget: guideDetailMoveHandle,
    eventOptions: { passive: true },
    drag: {
      filterTaps: true,
    },
  }
)

const isDragging = ref(false)

function handleSurfaceDrag({ movement: [x, y], dragging, swipe }) {
  height.value =
    windowHeight.value - guideDetailSheetPosition.value - useRound(y).value
  isDragging.value = dragging
  // const offset = sheet.value.getBoundingClientRect()
  const close = findClosestSnapPoint(
    guideDetailSheetPosition.value + useRound(y).value
  )

  if (swipe[1] !== 0) {
    const next = findNextSnapPoint(
      guideDetailSheetPosition.value + useRound(y).value,
      swipe[1]
    )
    height.value = windowHeight.value - next
  }
  if (!dragging) {
    guideDetailSheetPosition.value = close
    height.value = windowHeight.value - close
    return
  }

  // update initial point for next drag
  gestureModule.config.drag.initial = [x, 0]
}

// find closest snap point
const findClosestSnapPoint = (currentYPosition) => {
  const positions = []
  positions.push(snapTopPosition.value)
  positions.push(snapMiddlePosition.value)
  positions.push(snapBottomPosition.value)

  const closest = positions.reduce(function (prev, curr) {
    return Math.abs(curr - currentYPosition) < Math.abs(prev - currentYPosition)
      ? curr
      : prev
  })

  return closest
}

// find next snap point
const findNextSnapPoint = (currentYPosition, swipeDirection) => {
  const positions = []
  positions.push(snapTopPosition.value)
  positions.push(snapMiddlePosition.value)
  positions.push(snapBottomPosition.value)

  const next = positions.reduce(function (prev, curr) {
    if (swipeDirection === -1) {
      return curr < currentYPosition ? curr : prev
    } else {
      return curr > currentYPosition ? curr : prev
    }
  })

  return next
}

const close = async () => {
  if (searchText) {
    searchText.value = null
  }
  await closeSelectedGuide()
}

watch(
  selectedGuide,
  async () => {
    if (selectedGuide.value && import.meta.client) {
      $log('components: selectedGuide: selectedGuide changed')
      await $metric({ key: 'PAGE_VIEW', guideId: selectedGuide.value.id })
    }
  },
  { immediate: true }
)

watch(
  guideDetailSheetPosition,
  () => {
    // assign current position to currentPosition by comparing guideDetailSheetPosition to snapTopPosition, snapMiddlePosition, snapBottomPosition. Assign top, middle, bottom.
    if (guideDetailSheetPosition.value === snapTopPosition.value) {
      currentPosition.value = 'top'
    } else if (guideDetailSheetPosition.value === snapMiddlePosition.value) {
      currentPosition.value = 'middle'
    } else if (guideDetailSheetPosition.value === snapBottomPosition.value) {
      currentPosition.value = 'bottom'
    } else {
      currentPosition.value = null
    }
  },
  { immediate: true }
)

const openGraphData = computed(() => ({
  title: guideTitle.value || '',
  ogTitle: guideTitle.value || '',
  description: guideShortDescription.value || '',
  ogDescription: guideShortDescription.value || '',
  ogImage: guideMediumUrl.value || '',
  url: 'https://quo.space' + currentRoute.value.fullPath,
  twitterCard: 'summary_large_image',
}))

// Set OpenGraph meta immediately during SSR
if (import.meta.server) {
  setOpenGraph(openGraphData.value)
}

// Watch for client-side updates
if (import.meta.client) {
  watch(
    openGraphData,
    (newData) => {
      setOpenGraph(newData)
    },
    {
      immediate: true,
      deep: true,
    }
  )
}

$log('components: GuideDetail:setup:end')
</script>
