<template>
  <div
    class="flex flex-col mx-2 sm:mx-4 bg-panel-background sm:rounded-xl pointer-events-auto h-[40vh] sm:h-full overflow-y-hidden rounded-t-xl"
  >
    <div class="flex border-b border-panel-hr-border">
      <div class="flex-grow">&nbsp;</div>
      <div class="">
        <div class="py-2 px-4">
          <button
            type="button"
            class="bg-panel-nav-control-background w-8 h-8 rounded-lg bg-opacity-60 pointer-events-auto"
            @click="searchText = null"
          >
            <SvgIcon
              name="x-mark"
              class="stroke-panel-nav-control-action-symbol h-5 w-5 my-auto mx-auto"
            />
          </button>
        </div>
      </div>
    </div>
    <div class="overflow-y-auto">
      <div v-for="(result, index) in fullSearchResult" :key="index">
        <NuxtLink
          class="m-1 cursor-pointer border-b border-panel-hr-border block"
          :to="createRoute(result)"
        >
          <div
            class="flex flex-row"
            :class="
              result.type === 'guide'
                ? 'bg-teal-200 m-1 p-2 rounded-lg ring-1 ring-inset ring-teal-300 bg-opacity-60 backdrop-blur-sm'
                : 'p-3'
            "
          >
            <div class="flex-none mr-3">
              <div class="px-1 pt-0.5 rounded-full">
                <SvgIcon
                  v-if="result.type === 'item'"
                  :name="getItemIcon(result, locale).name"
                  type="solid"
                  class="h-5 w-5 fill-panel-accent-text"
                />
                <SvgIcon
                  v-if="result.type === 'guide'"
                  name="newspaper"
                  type="solid"
                  class="h-5 w-5 fill-panel-accent-text"
                />
              </div>
            </div>
            <div class="grow flex flex-col min-w-0 space-y-1">
              <div class="text-md font-bold text-panel-accent-text">
                {{
                  result.type === 'item'
                    ? getItemTitle(result, locale)
                    : getGuideTitle(result, locale) || result.name
                }}
              </div>
              <div class="text-sm text-panel-secondary-text">
                <template v-if="result.type === 'item'">
                  <span
                    v-for="(tag, tagIndex) in getItemSubTitleTags(
                      result,
                      locale
                    )"
                    :key="tagIndex"
                  >
                    <span v-if="tagIndex > 0"> &middot; </span>
                    {{ tag.short }}
                  </span>
                </template>
                <span v-if="result.address && result.address.city">
                  &middot; {{ result.address.city }}</span
                >
                <span v-if="result.address && result.address.country">
                  &middot; {{ result.address.country }}</span
                >
                <span
                  v-if="result.type === 'guide'"
                  class="capitalize text-panel-accent-text"
                >
                  {{ result.guideType }} Guide
                </span>
              </div>
            </div>
          </div>
        </NuxtLink>
      </div>
      <div v-if="searchResult.length === 0" class="p-4 text-panel-primary-text">
        Nothing found
      </div>
    </div>
  </div>
</template>
<script setup>
const { $log } = useNuxtApp()
const { locale } = useI18n()
const { slugify } = useSlugify()

const searchStore = useSearchStore()
const { searchText } = storeToRefs(searchStore)

const mapStore = useMapStore()
const { mapFullSwLngLat, mapFullNeLngLat } = storeToRefs(mapStore)

const client = useSupabaseClient()

const { getItemTitle } = useItem()
const { getItemIcon, getItemSubTitleTags } = useTag()

const { getGuideTitle } = useGuide()

const { getItemResultAddress } = useAddress()

const localePath = useLocalePath()

const createRoute = (result) => {
  let route = {}
  if (result.type === 'item') {
    route = localePath({
      name: 'map-place-nanoId-slug',
      params: { nanoId: result.nanoId },
    })
  } else if (result.type === 'guide') {
    const localeTitle = getGuideTitle(result, locale) || result.name
    route = localePath({
      name: 'map-guide-nanoId-title-key',
      params: { nanoId: result.nanoId, title: slugify(localeTitle) },
    })
  }
  return route
}

const { data: searchResult } = await useAsyncData(
  'searchText',
  async () => {
    const { data, error } = await client.rpc('search_by_box', {
      search_text: searchText.value,
      search_language: 'english',
      min_lon: mapFullSwLngLat.value.lng,
      min_lat: mapFullSwLngLat.value.lat,
      max_lon: mapFullNeLngLat.value.lng,
      max_lat: mapFullNeLngLat.value.lat,
    })
    if (error) {
      $log('components/QuickResult: error: ', error)
      return []
    }
    return data
  },
  {
    watch: [searchText, mapFullSwLngLat.value, mapFullNeLngLat.value],
  }
)

const fullSearchResult = computed(() => {
  // lookup address for each result
  const resultWithAddress = searchResult.value.map((result) => {
    const address =
      result.type === 'item' ? getItemResultAddress(result, locale) : null
    return { ...result, address }
  })
  return resultWithAddress
})
</script>
