<template>
  <div v-if="fullItem">
    <div class="flex flex-row">
      <NuxtLink
        class="flex flex-none w-12 h-12 mr-2 rounded-full items-center justify-center"
        :class="'bg-tag-' + fullItem.icon.bgColor"
        :to="fullItem.route"
        @click="$metric({ key: 'FEED_ITEM_CLICK', itemId: fullItem.id })"
      >
        <SvgIcon
          :name="fullItem.icon.name"
          type="solid"
          :class="'h-6 w-6 fill-tag-' + fullItem.icon.color"
        />
      </NuxtLink>
      <div class="grow flex flex-col text-sm min-w-0 space-y-3">
        <NuxtLink
          class="flex flex-col space-y-2"
          :to="fullItem.route"
          @click="$metric({ key: 'FEED_ITEM_CLICK', itemId: fullItem.id })"
        >
          <div class="flex flex-auto font-bold text-panel-accent-text">
            {{ fullItem.title }}
          </div>
          <div class="text-panel-secondary-text truncate">
            <span v-for="(tag, tIndex) in fullItem.titleTags" :key="tIndex">
              <span v-if="tIndex > 0"> &middot; </span>
              {{ tag.short }}
            </span>
            <span v-if="fullItem.city">
              &middot;
              {{ fullItem.city }}
            </span>
            <span v-if="fullItem.country">
              &middot;
              {{ fullItem.country }}
            </span>
          </div>
        </NuxtLink>
        <NuxtLink
          v-if="fullItem.feedText"
          class="my-2 text-panel-accent-text text-sm tracking-tight"
          :to="fullItem.route"
          @click="$metric({ key: 'FEED_ITEM_CLICK', itemId: fullItem.id })"
        >
          {{ fullItem.feedText }}
        </NuxtLink>
        <NuxtLink
          v-else-if="fullItem.shortDescription"
          class="my-2 text-panel-accent-text text-sm tracking-tight"
          :to="fullItem.route"
          @click="$metric({ key: 'FEED_ITEM_CLICK', itemId: fullItem.id })"
        >
          {{ fullItem.shortDescription }}
        </NuxtLink>
        <NuxtLink
          :to="fullItem.route"
          @click="$metric({ key: 'FEED_ITEM_CLICK', itemId: fullItem.id })"
        >
          <img
            v-if="fullItem.mediumUrl"
            :src="fullItem.mediumUrl"
            alt=""
            class="w-full h-44 object-cover rounded"
          />
        </NuxtLink>

        <div
          class="flex justify-between items-center text-xs text-panel-secondary-text"
        >
          <div>
            {{ fullItem.shortDateTime }}
          </div>
          <div
            v-if="isQuoPro"
            class="cursor-pointer"
            @click="toggleLike(fullItem.id)"
          >
            <SvgIcon
              v-if="fullItem.likeState === 'none'"
              name="heart"
              class="w-5 h-6"
            />
            <SvgIcon
              v-if="fullItem.likeState === 'like'"
              name="heart"
              type="solid"
              class="w-5 h-6 fill-pink-500"
            />
            <SvgIcon
              v-if="fullItem.likeState === 'unlike'"
              name="heart-slash"
              type="solid"
              class="w-5 h-6 fill-gray-400"
            />
          </div>
          <div>
            <SvgIcon
              name="bookmark"
              :type="fullItem.isInAnyList ? 'solid' : 'outline'"
              class="w-5 h-6 cursor-pointer"
              @click="controllerStore.openBottomSheetList(fullItem.id)"
            />
          </div>
          <div
            class="flex flex-row items-center cursor-pointer"
            @click="highlightItem(fullItem.id)"
          >
            <SvgIcon name="map" class="w-5 h-6 mr-1" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  featuredAt: {
    type: String,
    default: null,
  },
  itemId: {
    type: String,
    default: null,
  },
})

const { $metric } = useNuxtApp()
const controllerStore = useControllerStore()
const itemStore = useItemStore()
const { getItemFeedText, getItemShortDescription, getItemTitle, getMediumUrl } =
  useItem()
const { getItemIcon, getItemSubTitleTags } = useTag()
const { intShortDateTime } = useDateTime()
const likeStore = useLikeStore()
const listStore = useListStore()
const { locale } = useI18n()
const profileStore = useProfileStore()
const { isQuoPro } = storeToRefs(profileStore)

const item = ref(null)

const fullItem = computed(() => {
  if (!item.value) return null

  let city = null
  if (item.value.addresses && item.value.addresses.length > 0) {
    const address = item.value.addresses.find(
      (address) => address.type === 'city'
    )
    if (address) {
      city = address.value
    }
  }
  let country = null
  if (item.value.addresses && item.value.addresses.length > 0) {
    const address = item.value.addresses.find(
      (address) => address.type === 'country'
    )
    if (address) {
      country = address.value
    }
  }
  const icon = getItemIcon(item.value, locale)
  const route = createRoute(item.value)
  const title = getItemTitle(item.value, locale)
  const titleTags = getItemSubTitleTags(
    item,
    locale,
    'FeedResult:v-for-fullFeed:tags'
  )
  const feedText = getItemFeedText(item.value, locale)
  const shortDescription = getItemShortDescription(item.value, locale)
  const mediumUrl = getMediumLegacyUrl(item.value)
  const shortDateTime = intShortDateTime(props.featuredAt, locale)
  const newItem = {
    ...item.value,
    likeState: likeStore.getLikeStateByItemId(item.value.id),
    isInAnyList: listStore.isItemInAnyAddableList(item.value.id),
    city,
    country,
    icon,
    route,
    title,
    titleTags,
    feedText,
    shortDescription,
    mediumUrl,
    shortDateTime,
  }
  return newItem
})

const localePath = useLocalePath()

const createRoute = (result) => {
  let route = {}
  route = localePath({
    name: 'map-place-nanoId-slug',
    params: { nanoId: result.nanoId },
  })
  return route
}

const getMediumLegacyUrl = (item) => {
  const url = getMediumUrl(item.media[0])
  if (url !== null) return url
  if (item.legacy_media !== undefined && item.legacy_media.length > 0) {
    return item.legacy_media[0].url
  }
  return null
}

const highlightItem = (id) => {
  itemStore.setHighlightedItemIds([id])
}

const toggleLike = async (id) => {
  await likeStore.toggleLike(id)
}

watch(
  () => props.itemId,
  async (itemId) => {
    item.value = await itemStore.getOrFetchItemById(itemId)
  },
  { immediate: true }
)
</script>
