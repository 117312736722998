<template>
  <div
    ref="feedResultsheet"
    v-scroll="onScroll"
    class="mx-2 sm:mx-4 bg-panel-background rounded-t-2xl overflow-hidden overflow-y-auto pointer-events-auto sm:h-screen no-scrollbar"
    :style="xs ? 'height: ' + height + 'px;' : ''"
  >
    <div class="sticky top-0">
      <div class="relative bg-panel-background/90">
        <div class="h-14">&nbsp;</div>
        <div
          ref="feedResultMoveHandle"
          class="absolute w-full h-full top-0"
        ></div>
        <div
          class="absolute w-full h-full top-0 grid grid-cols-1 gap-4 content-between pointer-events-none p-1"
        >
          <div class="flex flex-row justify-between">
            <div class="p-2 pointer-events-auto">
              <div class="w-8">&nbsp;</div>
              <button
                type="button"
                :class="[
                  'bg-panel-nav-control-background',
                  ' w-8 h-8 rounded-lg bg-opacity-60 pointer-events-auto hidden',
                ]"
              >
                <SvgIcon
                  name="chevron-left"
                  :class="[
                    'stroke-panel-nav-control-action-symbol',
                    'h-5 w-5 my-auto mx-auto',
                  ]"
                />
              </button>
            </div>
            <div class="p-2 flex flex-col">
              <div
                :class="[
                  'border-panel-nav-control-background',
                  'w-12 h-0 border-2  bg-opacity-60 rounded sm:hidden',
                ]"
              >
                &nbsp;
              </div>
              <div
                class="flex justify-center pt-1 font-medium text-panel-accent-text"
              >
                Feed
              </div>
            </div>
            <div class="p-2">
              <button
                type="button"
                :class="[
                  'bg-panel-nav-control-background',
                  'w-8 h-8 rounded-lg bg-opacity-60 pointer-events-auto',
                ]"
                @click="close()"
              >
                <SvgIcon
                  name="x-mark"
                  :class="[
                    'stroke-panel-nav-control-action-symbol',
                    'h-5 w-5 my-auto mx-auto',
                  ]"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-panel-accent-text text-2xl font-semibold px-3 pt-4 pb-3">
      {{ t('feed.title') }}
    </div>
    <!-- Feed -->
    <feed-result-item-card
      v-for="(element, index) in feedElements"
      :id="index + '--' + element.itemId"
      :key="index"
      :ref="element.itemId"
      v-intersection-observer="elementVisibility"
      :item-id="element.itemId"
      :featured-at="element.featuredAt"
      class="mx-3 my-1 px-2 pt-3 pb-2 bg-panel-mention-card-background rounded-lg"
    />
    <!-- Footer -->
    <div class="px-3 py-8">
      <div class="text-sm text-center text-slate-400">&copy; Quo space</div>
    </div>
  </div>
</template>
<script setup>
import { useGesture } from '@vueuse/gesture'
// import { useMotionProperties, useSpring } from '@vueuse/motion'
import { useRound } from '@vueuse/math'
import {
  breakpointsTailwind,
  useBreakpoints,
  useScrollLock,
  useWindowSize,
} from '@vueuse/core'
import { vIntersectionObserver, vScroll } from '@vueuse/components'
import { formatISO } from 'date-fns'

const { $log, $metric } = useNuxtApp()

onUpdated(() => {
  $log('components: FeedResult: onUpdated')
})

onUnmounted(() => {
  $log('components: FeedResult: onUnmounted')
})

const { t } = useI18n()

const breakpoints = useBreakpoints(breakpointsTailwind)
const xs = breakpoints.smaller('sm')

const controllerStore = useControllerStore()
const {
  feedResultSheetPosition,
  snapBottomPosition,
  snapMiddlePosition,
  snapTopPosition,
} = storeToRefs(controllerStore)

const { closeFeedResult } = useController()

const feedStore = useFeedStore()
const { featured } = storeToRefs(feedStore)

$log('components/FeedResult: featured:', featured.value?.length)

const page = ref(1)

const supabase = useSupabaseClient()

const feedElements = ref([])

const updateFeedElements = (data) => {
  feedElements.value.push(...data)
}

const { data } = await useAsyncData(
  `feed-page:${page.value}`,
  async () => {
    const { data } = await supabase
      .from('featured')
      .select('id, featuredAt:featured_at, itemId:item_id')
      .lt('featured_at', formatISO(new Date()))
      .order('featured_at', { ascending: false })
      .range(page.value * 10 - 10, page.value * 10 - 1)
    $log('store:featured:init:data: ', data)
    return data
  },
  { watch: [page] }
)

watch(
  data,
  (value) => {
    if (value) {
      updateFeedElements(value)
    }
  },
  { immediate: true, deep: true }
)

const close = async () => {
  await closeFeedResult()
}

const feedResultMoveHandle = ref()
const height = ref(0)
const feedResultsheet = ref()

const { height: windowHeight } = useWindowSize()

const isLocked = useScrollLock(feedResultsheet)

onMounted(() => {
  if (feedResultSheetPosition.value === 0) {
    controllerStore.setFeedResultSheetPosition(snapTopPosition.value)
  }
  $log(
    'components:FeedResult:onMounted:feedResultSheetPosition',
    feedResultSheetPosition.value
  )
  height.value = windowHeight.value - feedResultSheetPosition.value - 80
})

function onScroll(state) {
  const { y } = state
  const newDevInfo = {
    scrollYFeedResult: y,
  }
  controllerStore.addDevInfo(newDevInfo)
}

const gestureModule = useGesture(
  {
    onDrag: handleSurfaceDrag,
  },
  {
    domTarget: feedResultMoveHandle,
    eventOptions: { passive: true },
    drag: {
      filterTaps: true,
    },
  }
)

function handleSurfaceDrag({ movement: [x, y], dragging, swipe }) {
  height.value =
    windowHeight.value - feedResultSheetPosition.value - useRound(y).value - 80
  isLocked.value = dragging
  // const offset = feedResultsheet.value.getBoundingClientRect()
  const close = findClosestSnapPoint(
    feedResultSheetPosition.value + useRound(y).value
  )

  if (swipe[1] !== 0) {
    const next = findNextSnapPoint(
      feedResultSheetPosition.value + useRound(y).value,
      swipe[1]
    )
    height.value = windowHeight.value - next - 80
  }
  if (!dragging) {
    feedResultSheetPosition.value = close
    height.value = windowHeight.value - close - 80
    return
  }

  // update initial point for next drag
  gestureModule.config.drag.initial = [x, 0]
}

// find closest snap point
const findClosestSnapPoint = (currentYPosition) => {
  const positions = []
  positions.push(snapTopPosition.value)
  positions.push(snapMiddlePosition.value)
  positions.push(snapBottomPosition.value)

  const closest = positions.reduce(function (prev, curr) {
    return Math.abs(curr - currentYPosition) < Math.abs(prev - currentYPosition)
      ? curr
      : prev
  })

  return closest
}

// find next snap point
const findNextSnapPoint = (currentYPosition, swipeDirection) => {
  const positions = []
  positions.push(snapTopPosition.value)
  positions.push(snapMiddlePosition.value)
  positions.push(snapBottomPosition.value)

  const next = positions.reduce(function (prev, curr) {
    if (swipeDirection === -1) {
      return curr < currentYPosition ? curr : prev
    } else {
      return curr > currentYPosition ? curr : prev
    }
  })

  return next
}

const elementVisibility = async ([params]) => {
  const { isIntersecting } = params
  const indexItemId = params.target.id
  const index = indexItemId.split('--')[0]
  const itemId = indexItemId.split('--')[1]
  $log('components:FeedResult:elementVisibility:index', index)
  $log('components:FeedResult:elementVisibility:itemId', itemId)

  const pageTreshold = page.value * 10 - 3

  if (isIntersecting) {
    if (index >= pageTreshold) {
      page.value++
    }
  }

  if (isIntersecting && itemId) {
    await $metric({ key: 'FEED_ITEM_VIEW', itemId })
  }
}

await $metric({
  key: 'PAGE_VIEW',
})
</script>
