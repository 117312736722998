<template>
  <div>
    <div>
      <div
        v-if="guidesFull && guidesFull.length"
        class="inline-flex bg-teal-200 bg-opacity-60 backdrop-blur-sm ring-inset ring-teal-300 mb-2 sm:mb-3 sm:my-4 rounded-lg ring-1"
      >
        <NuxtLink
          is="button"
          :key="guidesFull[0].id"
          type="button"
          class="inline-flex items-center gap-x-1.5 text-sm py-1.5 px-2 whitespace-nowrap tracking-tight"
          :to="
            localePath({
              name: 'map-guide-nanoId-title-key',
              params: {
                nanoId: guidesFull[0].nanoId,
                title: slugify(guidesFull[0].nameTranslated),
              },
            })
          "
        >
          <SvgIcon name="newspaper" class="h-5 w-5" />
          {{ guidesFull[0].nameTranslated }}
        </NuxtLink>
        <button
          v-if="guidesFull.length > 1"
          type="button"
          class="flex items-center"
          @click.stop="handleToggleGuideDropdown"
        >
          <div class="h-5 w-[1px] bg-black/20 mx-0"></div>
          <SvgIcon
            name="chevron-down"
            class="h-4 w-4 ml-1.5 mr-2"
            :class="isGuideDropdownOpen ? 'rotate-180 transform' : ''"
          />
        </button>
      </div>
      <!-- Dropdown menu -->
      <div
        v-if="isGuideDropdownOpen"
        v-click-outside="handleCloseGuideDropdown"
        class="absolute bg-teal-200 bottom-full mb-2 left-2 sm:top-full sm:bottom-auto sm:left-4 sm:mt-0 sm:mb-0 w-32 rounded-lg bg-opacity-60 backdrop-blur-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-visible"
        @click.stop
      >
        <div class="py-1 flex flex-col divide-y divide-black/20">
          <NuxtLink
            v-for="guide in guidesFull.slice(1)"
            :key="guide.id"
            :to="
              localePath({
                name: 'map-guide-nanoId-title-key',
                params: {
                  nanoId: guide.nanoId,
                  title: slugify(guide.nameTranslated),
                },
              })
            "
            class="block px-4 py-2 text-sm hover:text-gray-900 mx-1 truncate tracking-tight"
            @click="handleCloseGuideDropdown"
          >
            {{ guide.nameTranslated }}
          </NuxtLink>
        </div>
      </div>
    </div>
    <NuxtLink
      is="button"
      v-for="(list, index) in shortcutsBarLists"
      :key="index"
      type="button"
      class="inline-flex items-center gap-x-1.5 rounded-lg bg-shortcut-background bg-opacity-80 backdrop-blur-sm text-sm text-shortcut-text ring-[.5px] ring-inset ring-shortcut-border py-1.5 px-2 whitespace-nowrap drop-shadow-[0_1px_1px_rgba(0,0,0,0.1)] mb-2 sm:mb-3 sm:my-4"
      active-class="!bg-shortcut-active-background !text-shortcut-active-text !ring-shortcut-active-border !bg-opacity-80 "
      :to="
        localePath({
          name: 'map-list-nanoId-slug',
          params: { nanoId: list.nanoId },
        })
      "
    >
      <SvgIcon :name="list.icons.name" class="h-5 w-5" />
      {{ getListTitle(list.name, list.purpose, t) }}
    </NuxtLink>
    <NuxtLink
      is="button"
      v-for="highlight in highlights"
      :key="highlight.id"
      type="button"
      class="inline-flex items-center gap-x-1.5 rounded-lg bg-shortcut-background bg-opacity-80 backdrop-blur-sm text-sm text-shortcut-text ring-[.5px] ring-inset ring-shortcut-border py-1.5 px-2 whitespace-nowrap drop-shadow-[0_1px_1px_rgba(0,0,0,0.1)] mb-2 sm:mb-3 sm:my-4 cursor-pointer tracking-tight"
      active-class="!bg-shortcut-active-background !text-shortcut-active-text !ring-shortcut-active-border !bg-opacity-80 "
      :to="
        localePath({
          name: 'map-highlight-highlightUrlName',
          params: { highlightUrlName: highlight.translation.urlName },
        })
      "
    >
      <SvgIcon
        :name="highlight.icon"
        :type="highlight.iconType"
        class="h-4 w-4"
      />
      {{ highlight.translation.name }}
    </NuxtLink>
  </div>
</template>
<script setup>
import { vOnClickOutside } from '@vueuse/components'
// const { $log } = useNuxtApp()
const { locale, t } = useI18n()
const listStore = useListStore()
const { shortcutsBarLists } = storeToRefs(listStore)
const { getListTitle } = useList()
const localePath = useLocalePath()
const { slugify } = useSlugify()

const mapStore = useMapStore()
const { mapFullSwLngLat, mapFullNeLngLat, mapVisibleBounds } =
  storeToRefs(mapStore)

const { getGuideTitle } = useGuide()

const supabase = useSupabaseClient()

const searchStore = useSearchStore()
const { getLocalHighlights } = storeToRefs(searchStore)

const highlights = computed(() => {
  return getLocalHighlights.value(locale.value)
})

const isGuideDropdownOpen = ref(false)

const { data: guides } = await useAsyncData(
  `guides_by_box:${mapFullSwLngLat.value.lng}:${mapFullSwLngLat.value.lat}:${mapFullNeLngLat.value.lng}:${mapFullNeLngLat.value.lat}`,
  async () => {
    const { data } = await supabase.rpc('guides_by_box', {
      min_x: mapVisibleBounds.value.getWest(), // mapFullSwLngLat.value.lng,
      min_y: mapVisibleBounds.value.getSouth(), // mapFullSwLngLat.value.lat,
      max_x: mapVisibleBounds.value.getEast(), // mapFullNeLngLat.value.lng,
      max_y: mapVisibleBounds.value.getNorth(), // mapFullNeLngLat.value.lat,
    })

    return data
  },
  {
    lazy: true,
    server: false,
    watch: [mapFullSwLngLat, mapFullNeLngLat],
  }
)

const guidesFull = computed(() => {
  if (!guides.value?.length) return []

  return guides.value.map((guide) => ({
    ...guide,
    nameTranslated: getGuideTitle(guide, locale) || guide.name,
  }))
})

const handleToggleGuideDropdown = () => {
  isGuideDropdownOpen.value = !isGuideDropdownOpen.value
}

const handleCloseGuideDropdown = () => {
  if (!event.target.closest('button')) {
    isGuideDropdownOpen.value = false
  }
}

const vClickOutside = vOnClickOutside
</script>
