<template>
  <div
    class="absolute bottom-4 bg-panel-background rounded-lg inset-x-2 w-auto sm:w-[640px] mx-auto"
  >
    <div class="flex flex-col">
      <div class="flex justify-between items-center mb-3 border-b py-3 px-4">
        <div class="w-6">&nbsp;</div>
        <div class="text-md font-medium text-panel-accent-text">
          {{ $t('bottomSheetPlaceCreate.title') }}
        </div>
        <div class="cursor-pointer" @click="closeBottomSheet()">
          <SvgIcon name="x-mark" class="w-6 h-6 stroke-panel-action" />
        </div>
      </div>
      <div
        class="flex flex-col mx-auto space-y-4 font-normal text-sm text-panel-accent-text px-4 pt-4 pb-6"
      >
        <div v-if="state === 'showInstruction'" class="flex flex-col space-y-4">
          <span>{{
            $t('bottomSheetPlaceCreateQuo.instruction.goToPlace')
          }}</span>
          <img src="/images/add-new-place.png" />
          <span>{{ $t('bottomSheetPlaceCreateQuo.instruction.zoomIn') }}</span>
          <span>{{
            $t('bottomSheetPlaceCreateQuo.instruction.switchToSatelliteView')
          }}</span>
          <span>{{
            $t('bottomSheetPlaceCreateQuo.instruction.activateGps')
          }}</span>
        </div>
        <Form
          is="div"
          v-if="state === 'showPlaceCreateForm'"
          v-slot="{ errors }"
          class="flex flex-col space-y-5"
          @submit="onSubmit"
        >
          <div v-if="method === 'pasteGpsCoordinates'" class="flex flex-row">
            <div class="flex-auto">
              <div class="ml-4 mb-1 text-panel-list-text text-sm">
                {{ $t('bottomSheetPlaceCreateQuo.addPlace.latitude') }}
              </div>
              <div
                class="relative flex items-center bg-panel-list-pill-background rounded-full"
              >
                <Field
                  v-model="lat"
                  name="lat"
                  type="text"
                  class="ml-4 mr-1 text-sm font-medium leading-6 py-2 text-panel-list-text bg-transparent w-full px-0 border-0 focus:ring-0"
                  placeholder="47.877738"
                  autocomplete="off"
                  :rules="validateLat"
                />
              </div>
            </div>
            <div class="flex mx-1 items-end justify-center cursor-pointer">
              <div
                class="bg-panel-list-pill-background rounded-full"
                @click="switchLatLon()"
              >
                <SvgIcon
                  name="arrows-right-left"
                  type="solid"
                  class="w-5 h-5 fill-panel-list-text m-2.5"
                />
              </div>
            </div>
            <div class="flex-auto">
              <div class="ml-4 mb-1 text-panel-list-text text-sm">
                {{ $t('bottomSheetPlaceCreateQuo.addPlace.longitude') }}
              </div>
              <div
                class="relative flex items-center bg-panel-list-pill-background rounded-full"
              >
                <Field
                  v-model="lon"
                  name="lon"
                  type="text"
                  class="ml-4 mr-1 !outline-none text-sm font-medium leading-6 py-2 text-panel-list-text bg-transparent w-full px-0 border-0 focus:ring-0"
                  placeholder="47.877738"
                  :rules="validateLon"
                />
              </div>
            </div>
          </div>
          <div
            v-if="errors && (errors.lat || errors.lon)"
            class="mx-4 text-panel-alert-message text-sm"
          >
            <ErrorMessage name="lat" /><ErrorMessage name="lon" />
          </div>
          <div
            v-if="method === 'pasteGpsCoordinates'"
            class="flex items-center justify-center border-2 border-panel-list-pill-border rounded-full text-panel-list-text py-2 cursor-pointer"
            @click="pasteLatLon()"
          >
            <SvgIcon
              name="clipboard-document"
              class="outline-panel-list-text w-5 h-5 mr-1"
              type="outline"
            />
            {{ $t('bottomSheetPlaceCreateQuo.addPlace.pasteFromClipboard') }}
          </div>
          <div
            v-if="pasteFromClipboardErrorMessage"
            class="mx-4 !mt-2 text-panel-alert-message text-sm"
          >
            {{ pasteFromClipboardErrorMessage }}}
          </div>
          <div
            v-if="method === 'pasteGpsCoordinates'"
            class="px-4 !mt-2 text-xs text-panel-secondary-text"
          >
            {{
              $t(
                'bottomSheetPlaceCreateQuo.addPlace.supportedCoordinatesFormats'
              )
            }}
          </div>

          <div
            v-if="method === 'pasteGoogleMapsLink' && false"
            class="flex items-center justify-center border-2 border-panel-list-pill-border rounded-full text-panel-list-text py-2 cursor-pointer"
            @click="pasteGoogleMapLink()"
          >
            <SvgIcon
              name="clipboard-document"
              class="outline-panel-list-text w-5 h-5 mr-1"
              type="outline"
            />
            {{ $t('bottomSheetPlaceCreateQuo.addPlace.pasteFromGoogleMaps') }}
          </div>
          <div
            v-if="method === 'pasteGoogleMapsLink'"
            class="relative rounded-full bg-panel-list-pill-background"
          >
            <input
              v-model="pastedGoogleMapsUrl"
              :placeholder="
                $t('bottomSheetPlaceCreateQuo.addPlace.pasteGoogleMapsLinkHere')
              "
              type="text"
              autocomplete="off"
              data-1p-ignore
              class="block w-full rounded-full pl-4 text-sm text-panel-list-text bg-transparent border-transparent focus:border-transparent focus:ring-0 border-0 pr-12"
            />
            <div
              v-if="true"
              class="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
            >
              <SvgIcon
                name="clipboard-document"
                class="outline-panel-list-text w-5 h-5 mr-1"
                type="outline"
                @click="pasteGoogleMapLink()"
              />
            </div>
          </div>
          <div
            v-if="
              method === 'pasteGoogleMapsLink' &&
              pasteGoogleMapsLinkErrorMessage
            "
            class="px-4 !mt-2 text-panel-alert-message text-sm"
          >
            {{ pasteGoogleMapsLinkErrorMessage }}
          </div>
          <div
            v-if="
              method === 'pasteGoogleMapsLink' &&
              fillFromUrlLatLonPairs.length > 1
            "
            class="flex flex-col"
          >
            <div>
              {{
                $t(
                  'bottomSheetPlaceCreateQuo.addPlace.pasteFromGoogleMapsMultipleCoordinatesFound'
                )
              }}
            </div>
            <div class="mt-2 space-y-2">
              <div
                v-for="(pair, index) in fillFromUrlLatLonPairs"
                :key="index"
                class="flex justify-center cursor-pointer space-y-2 rounded-full p-2 border-2"
                :class="{
                  'bg-panel-list-pill-background': pair.isSelected === false,
                  'bg-violet-200 border-panel-action': pair.isSelected === true,
                }"
                @click="selectPair(pair)"
              >
                {{ pair.lat }}, {{ pair.lon }}
              </div>
            </div>
          </div>
          <div>
            <div class="ml-4 mb-1 text-panel-list-text text-sm">
              {{ $t('bottomSheetPlaceCreateQuo.addPlace.placeTitle') }}
            </div>
            <div
              class="relative flex items-center bg-panel-list-pill-background rounded-full"
            >
              <Field
                v-model="placeTitle"
                name="placeTitle"
                type="text"
                class="ml-4 text-sm font-medium leading-6 py-2 text-panel-list-text bg-transparent border-transparent focus:border-transparent focus:ring-0 border-0 px-0"
                :placeholder="
                  $t('bottomSheetPlaceCreateQuo.addPlace.placeTitlePlaceholder')
                "
                autocomplete="off"
                :rules="validatePlaceTitle"
              />
            </div>
            <div class="ml-4 mt-1 text-panel-alert-message text-sm">
              <ErrorMessage name="placeTitle" />
            </div>
          </div>
          <div>
            <div class="ml-4 mb-1 text-panel-list-text text-sm">
              {{ $t('bottomSheetPlaceCreateQuo.addPlace.category') }}
            </div>
            <div
              class="relative flex items-center bg-panel-list-pill-background rounded-full"
            >
              <Combobox v-model="selectedTag">
                <div class="relative w-full">
                  <ComboboxInput
                    class="leading-6 py-2 font-medium text-sm text-panel-list-text bg-transparent px-4 border-transparent focus:border-transparent focus:ring-0"
                    :display-value="(tag) => tag.short"
                    @change="query = $event.target.value"
                  />
                  <ComboboxButton
                    class="absolute inset-y-0 right-0 flex items-center pl-2 pr-4 focus:outline-none"
                  >
                    <SvgIcon
                      name="chevron-down"
                      class="h-5 w-5 text-gray-400"
                      type="solid"
                    />
                  </ComboboxButton>
                  <ComboboxOptions
                    class="absolute z-10 mt-1 max-h-36 w-full overflow-auto rounded-xl bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  >
                    <ComboboxOption
                      v-if="queryTag"
                      v-slot="{ active, selected }"
                      as="template"
                      :value="queryTag"
                    >
                      <li
                        :class="[
                          'relative cursor-pointer select-none py-2 pl-3 pr-9',
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        ]"
                      >
                        <div class="flex items-center">
                          <div class="rounded-full w-6 h-6 p-1 bg-gray-400">
                            <SvgIcon
                              name="plus"
                              class="h-4 w-4 text-black"
                              type="solid"
                            />
                          </div>
                          <span
                            :class="[
                              'ml-3 truncate',
                              selected && 'font-semibold',
                            ]"
                          >
                            {{
                              $t(
                                'bottomSheetPlaceCreateQuo.addPlace.newCategory'
                              )
                            }}: {{ query }}
                          </span>
                        </div>

                        <span
                          v-if="selected"
                          :class="[
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600',
                          ]"
                        >
                          <SvgIcon name="check" class="h-5 w-5" type="solid" />
                        </span>
                      </li>
                    </ComboboxOption>
                    <ComboboxOption
                      v-for="tag in filteredTags"
                      :key="tag.id"
                      v-slot="{ active, selected }"
                      as="template"
                      :value="tag"
                    >
                      <li
                        :class="[
                          'relative cursor-pointer select-none py-2 pl-3 pr-9',
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        ]"
                      >
                        <div class="flex items-center">
                          <div
                            class="rounded-full w-6 h-6 p-1"
                            :class="'bg-tag-' + tag.bgColor"
                          >
                            <SvgIcon
                              :name="tag.iconName"
                              class="h-4 w-4"
                              type="solid"
                              :class="'fill-tag-' + tag.color"
                            />
                          </div>
                          <span
                            :class="[
                              'ml-3 truncate',
                              selected && 'font-semibold',
                            ]"
                          >
                            {{ tag.short }}
                          </span>
                          <span class="text-gray-400"
                            >&nbsp; in {{ tag.key }}</span
                          >
                        </div>

                        <span
                          v-if="selected"
                          :class="[
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600',
                          ]"
                        >
                          <SvgIcon name="check" class="h-5 w-5" type="solid" />
                        </span>
                      </li>
                    </ComboboxOption>
                  </ComboboxOptions>
                </div>
              </Combobox>
            </div>
            <div class="px-4 !mt-2 text-xs text-panel-secondary-text">
              {{ $t('bottomSheetPlaceCreateQuo.addPlace.categoryInstruction') }}
            </div>
            <div class="ml-4 mt-1 text-panel-alert-message text-sm">
              <ErrorMessage name="tag" />
            </div>
          </div>
          <AlertBox v-if="errorMessage" theme="error" :message="errorMessage" />
          <div class="px-4">
            {{ $t('bottomSheetPlaceCreateQuo.addPlace.agreeToTOS') }}
            <NuxtLink>{{
              $t('bottomSheetPlaceCreateQuo.addPlace.termsOfService')
            }}</NuxtLink
            >.
          </div>
          <button
            class="w-full py-2 bg-panel-action text-white rounded-full flex items-center justify-center"
            :disabled="loading"
            :class="{ 'opacity-50 cursor-not-allowed': loading }"
          >
            <SvgIcon
              v-if="loading"
              name="arrow-path"
              class="w-5 h-5 mr-2 animate-spin"
              type="solid"
            />
            {{ $t('bottomSheetPlaceCreateQuo.addPlace.createPlaceCTA') }}
          </button>
        </Form>
        <div
          v-if="state === 'showPlaceCreated'"
          class="flex flex-col space-y-5"
        >
          <div>{{ $t('bottomSheetPlaceCreateQuo.placeCreated.intro') }}</div>

          <ListItemCard
            v-if="createdItem"
            :item-result-or-item="createdItem"
            class="ring-2 ring-offset-1 ring-panel-action"
          />
          <div>
            {{ $t('bottomSheetPlaceCreateQuo.placeCreated.startWorking') }}
          </div>
          <div
            class="flex flex-row bg-panel-list-pill-background cursor-pointer rounded-full"
            @click="controllerStore.openBottomSheetList(createdItem.id)"
          >
            <div class="flex items-center justify-center">
              <SvgIcon
                name="bookmark"
                class="w-5 h-5 ml-3 mr-2"
                :class="{
                  'stroke-panel-list-pill-text': !isItemInList,
                  'fill-panel-list-pill-text': isItemInList,
                }"
                :type="isItemInList ? 'solid' : 'outline'"
              />
            </div>
            <div class="py-2">
              {{ $t('bottomSheetPlaceCreateQuo.placeCreated.addToListCTA') }}
            </div>
          </div>
          <div
            class="flex flex-row bg-panel-list-pill-background cursor-pointer rounded-full"
            @click="createCheckin()"
          >
            <div class="flex items-center justify-center">
              <SvgIcon
                name="check"
                class="w-5 h-5 ml-3 mr-2"
                :class="{
                  'stroke-panel-list-pill-text': !hasCheckin,
                  'stroke-green-500 stroke-2': hasCheckin,
                }"
                type="outline"
              />
            </div>
            <div class="py-2">
              {{ $t('bottomSheetPlaceCreateQuo.placeCreated.checkinCTA') }}
            </div>
          </div>
          <div>
            {{ $t('bottomSheetPlaceCreateQuo.placeCreated.thanks') }}
          </div>
          <div
            class="cursor-pointer flex justify-center py-2 text-white bg-panel-action rounded-full"
            @click="createAnotherPlace()"
          >
            {{ $t('bottomSheetPlaceCreateQuo.placeCreated.createNewPlaceCTA') }}
          </div>

          <div
            class="flex justify-center cursor-pointer border border-panel-action rounded-full py-2 text-panel-action"
            @click="closeBottomSheet()"
          >
            {{ $t('bottomSheetPlaceCreateQuo.placeCreated.closeCTA') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { Form, Field, ErrorMessage } from 'vee-validate'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
} from '@headlessui/vue'
const { $log } = useNuxtApp()
const controllerStore = useControllerStore()
const {
  newPlaceChosenMethod,
  newPlaceCreatedItemId,
  newPlaceLat,
  newPlaceLon,
} = storeToRefs(controllerStore)

const itemStore = useItemStore()

const listStore = useListStore()

const lat = ref(0)
const lon = ref(0)

const pastedContent = ref([])
const pastedGoogleMapsUrl = ref('')

const placeTitle = ref('')

const { locale } = useI18n()

const profileStore = useProfileStore()
const { isQuoPro } = storeToRefs(profileStore)

const createdItem = computed(() => {
  if (newPlaceCreatedItemId.value) {
    const item = itemStore.getItemById(newPlaceCreatedItemId.value)
    $log('BottomSheetPlaceCreateQuo createdItem', item)
    return item
  }
  return null
})

const method = computed(() => {
  return newPlaceChosenMethod.value
  // quoMap, pasteGpsCoordinates, pasteGoogleMapsLink
})

$log('BottomSheetPlaceCreateQuo method', method.value)

const state = computed(() => {
  if (
    (!newPlaceLat.value || !newPlaceLon.value) &&
    method.value === 'quoMap' &&
    !newPlaceCreatedItemId.value
  ) {
    return 'showInstruction'
  }
  if (newPlaceCreatedItemId.value) {
    return 'showPlaceCreated'
  }
  return 'showPlaceCreateForm'
})

const isItemInList = computed(() => {
  if (createdItem.value) {
    return listStore.isItemInAnyAddableList(createdItem.value.id)
  }
  return false
})

const checkinStore = useCheckinStore()
const { latestCheckinByItemId } = storeToRefs(checkinStore)

const createCheckin = async () => {
  $log('components: BottomSheetPlaceCreateQuo: createCheckin')
  await checkinStore.addCheckin(createdItem.value.id)
}

const hasCheckin = computed(() => {
  if (createdItem.value && latestCheckinByItemId.value(createdItem.value.id)) {
    $log('components: BottomSheetPlaceCreateQuo: hasCheckin')
    return true
  }
  return false
})

watch(
  () => hasCheckin.value,
  (newVal) => {
    $log('components: BottomSheetPlaceCreateQuo: hasCheckin', newVal)
  }
)

const { getCategoryTags } = useTag()

const categoryTags = computed(() => getCategoryTags(locale))

$log('components:BottomSheetListCreate: categoryTags: ', categoryTags.value)

const selectedTag = ref({ id: null, short: '' })

const query = ref('')

const queryTag = computed(() => {
  return query.value === '' ? null : { id: null, short: query.value }
})

const filteredTags = computed(() =>
  query.value === ''
    ? categoryTags.value
    : categoryTags.value.filter((tag) => {
        return tag.short.toLowerCase().includes(query.value.toLowerCase())
      })
)

$log('components:BottomSheetListCreate: filteredTags: ', filteredTags.value)

const { t } = useI18n()

const switchLatLon = () => {
  const tmpLat = lat.value
  const tmpLon = lon.value
  lat.value = tmpLon
  lon.value = tmpLat
}

const pasteLatLon = () => {
  pasteFromClipboardErrorMessage.value = null
  navigator.clipboard.readText().then((text) => {
    if (!text) {
      pasteFromClipboardErrorMessage.value = t(
        'bottomSheetPlaceCreateQuo.addPlace.pasteFromClipboardErrorClipboardEmpty'
      )
      return
    }

    // Clean up the input text by removing parentheses and extra spaces
    const cleanText = text.replace(/[()]/g, '').trim()
    const latLonStringArray = cleanText.split(',').map((s) => s.trim())
    const latLonIntArray = []

    if (latLonStringArray.length === 2) {
      latLonStringArray.forEach((element) => {
        if (!isNaN(element) && !isNaN(parseFloat(element))) {
          latLonIntArray.push(parseFloat(element))
        }
      })
      if (latLonIntArray.length === 2) {
        controllerStore.setNewPlaceLat(latLonIntArray[0])
        controllerStore.setNewPlaceLon(latLonIntArray[1])
        return
      }
    }

    const errorMessage =
      "'" +
      text +
      "' " +
      t('bottomSheetPlaceCreateQuo.addPlace.pasteFromClipboardErrorNotLatLon')
    pasteFromClipboardErrorMessage.value = errorMessage
  })
}

const pasteGoogleMapLink = async () => {
  pasteGoogleMapsLinkErrorMessage.value = null
  // navigator.clipboard.read().then((text) => {
  //   $log('components:BottomSheetPlaceCreateQuo:pasteGoogleMapLink: ', text)
  //   pastedContent.value = text
  //   pastedGoogleMapsUrl.value = text
  //   fetchLocationFromUrl(pastedGoogleMapsUrl.value)
  // })
  const clipboardText = await navigator.clipboard.readText()
  $log(
    'components:BottomSheetPlaceCreateQuo:pasteGoogleMapLink: ',
    clipboardText
  )
  pastedContent.value = clipboardText
  pastedGoogleMapsUrl.value = clipboardText
  // fetchLocationFromUrl(pastedGoogleMapsUrl.value)
}

watch(
  () => pastedGoogleMapsUrl.value,
  (value) => {
    // Check if value is a valid URL
    try {
      new URL(value)
      fetchLocationFromUrl(value)
    } catch (error) {
      // Not a valid URL, only fetch if it contains a URL within the text
      if (value && value.match(/\bhttps?:\/\/\S+/gi)) {
        fetchLocationFromUrl(value)
      }
    }
  }
)

const pasteFromClipboardErrorMessage = ref(null)
const pasteGoogleMapsLinkErrorMessage = ref(null)

const errorMessage = ref(null)
const loading = ref(false)

const handleCreatePlace = async (values) => {
  const itemStore = useItemStore()
  const response = await itemStore.createItem(values)

  $log('components:BottomSheetPlaceCreateQuo:response: ', response)

  if (response && response.id) {
    controllerStore.setNewPlaceCreatedItemId(response.id)
    controllerStore.setNewPlaceLat(null)
    controllerStore.setNewPlaceLon(null)
  } else {
    errorMessage.value = response
  }
}

const onSubmit = async (values) => {
  $log('components:BottomSheetPlaceCreateQuo:submit: values: ', values)
  errorMessage.value = null
  if (!isQuoPro) return
  if (selectedTag.value.id === null && selectedTag.value.short.length > 64) {
    errorMessage.value = t('bottomSheetPlaceCreateQuo.errors.categoryTooLong')
    return
  }
  if (!lat.value || !lon.value) {
    errorMessage.value = t('bottomSheetPlaceCreateQuo.errors.latLonRequired')
    return
  }
  values.tagId = selectedTag.value.id
  values.tagText = selectedTag.value.short
  values.lat = lat.value
  values.lon = lon.value
  values.name = values.placeTitle // TODO: change form to name
  loading.value = true
  await handleCreatePlace(values)
  loading.value = false
}

const validateLat = (value) => {
  if (!value) {
    return t('bottomSheetPlaceCreateQuo.errors.latRequired')
  }
  if (isNaN(value)) {
    return t('bottomSheetPlaceCreateQuo.errors.latMustBeNumeric')
  }
  return true
}

const validateLon = (value) => {
  if (!value) {
    return t('bottomSheetPlaceCreateQuo.errors.lonRequired')
  }
  if (isNaN(value)) {
    return t('bottomSheetPlaceCreateQuo.errors.lonMustBeNumeric')
  }
  return true
}

const validatePlaceTitle = (value) => {
  if (!(value && value.trim())) {
    return t('bottomSheetListCreate.errors.nameRequired')
  }
  if (value && value.length > 64) {
    return t('bottomSheetListCreate.errors.nameTooLong')
  }
  return true
}

watch(
  () => newPlaceLat.value,
  (value) => {
    lat.value = value
  },
  { immediate: true }
)
watch(
  () => newPlaceLon.value,
  (value) => {
    lon.value = value
  },
  { immediate: true }
)
watch(
  () => lat.value,
  (value) => {
    if (!isNaN(value)) {
      controllerStore.setNewPlaceLat(value)
    }
  }
)
watch(
  () => lon.value,
  (value) => {
    if (!isNaN(value)) {
      controllerStore.setNewPlaceLon(value)
    }
  }
)

const fetchLocationFromUrl = (urlText) => {
  if (urlText) {
    if (!urlText) return
    const urls = urlText.match(/\bhttps?:\/\/\S+/gi)
    $log('components:BottomSheetPlaceCreateQuo:urls: ', urls)
    if (urls && urls.length > 0) {
      const url = urls[0]
      if (url.includes('https://goo.gl/maps/')) {
        // https://goo.gl/maps/mvKMhnV2ivV6dY3TA
        fetchGoogleMapsHeaderLocation(url).then((response) => {
          if (response && response.location && response.location.length > 0) {
            $log(
              'components:BottomSheetPlaceCreateQuo:location: ',
              response.location
            )
            // https://www.google.com/maps/place/Brooklyn+Bridge/@40.6937265,-73.9886698,11z/data=!4m6!3m5!1s0x89c25a2343ce7b2b:0x2526ddba7abd465c!8m2!3d40.7060855!4d-73.9968643!16zL20vMGN2NGM?entry=tts&shorturl=1
            if (
              response.location.includes('https://www.google.com/maps/place/')
            ) {
              fillFormFromGoogleMapsUrl(response.location)
            }
          }
        })
      } else if (url.includes('https://www.google.com/maps/place/')) {
        fillFormFromGoogleMapsUrl(url)
      } else if (url.includes('https://maps.app.goo.gl/')) {
        // https://maps.app.goo.gl/CVGs6Sf8Mf1LHkvp6?g_st=ic
        fetchGoogleMapsHeaderLocation(url).then((response) => {
          if (response && response.location && response.location.length > 0) {
            $log(
              'components:BottomSheetPlaceCreateQuo:location: ',
              response.location
            )
            // https://maps.google.com?q=dein-auto.li+GmbH,+Peter-Kaiser-Strasse+73,+9493+Mauren&ftid=0x479b3b74375dc521:0x248766a9eaed8cfd&hl=de-DE&gl=ch&entry=gps&lucs=,47071704&g_ep=CAISBjYuNzMuMhgAINeCAyoJLDQ3MDcxNzA0QgJMSQ%3D%3D&g_st=ic
            if (
              response.location.includes('https://maps.google.com?q=') ||
              response.location.includes('https://www.google.com/maps/place/')
            ) {
              fillFormFromGoogleMapsUrl(response.location)
            }
          }
        })
      } else {
        const errorMessage =
          "'" +
          url +
          "' " +
          t(
            'bottomSheetPlaceCreateQuo.addPlace.pasteFromGoogleMapsErrorLinkNotRecognized'
          ) +
          ' https://www.google.com/maps/place/... or https://maps.google.com?q=... or https://maps.app.goo.gl/... or https://goo.gl/maps/'
        $log(
          'components:BottomSheetPlaceCreateQuo:errorMessage: ',
          errorMessage
        )
        pasteGoogleMapsLinkErrorMessage.value = errorMessage
      }
    } else {
      pasteGoogleMapsLinkErrorMessage.value =
        "'" +
        url +
        "' " +
        t(
          'bottomSheetPlaceCreateQuo.addPlace.pasteFromGoogleMapsErrorNoLinkFound'
        )
    }
  } else {
    pasteGoogleMapsLinkErrorMessage.value = t(
      'bottomSheetPlaceCreateQuo.addPlace.pasteFromGoogleMapsErrorClipboardEmpty'
    )
  }
}

const fillFromUrlLatLonPairs = ref([])

const fillFormFromGoogleMapsUrl = (url) => {
  if (url.includes('https://www.google.com/maps/place/')) {
    // https://www.google.com/maps/place/Brooklyn+Bridge/@40.6937265,-73.9886698,11z/data=!4m6!3m5!1s0x89c25a2343ce7b2b:0x2526ddba7abd465c!8m2!3d40.7060855!4d-73.9968643!16zL20vMGN2NGM?entry=tts&shorturl=1
    // https://www.google.com/maps/place/MUSEUM+OF+ICE+CREAM/@40.7185004,-73.9996737,14.54z/data=!4m12!1m5!3m4!2zNDDCsDQxJzM3LjQiTiA3M8KwNTknMTkuMiJX!8m2!3d40.6937265!4d-73.9886698!3m5!1s0x89c259e713d43875:0xbfd5eb3c3e1bb328!8m2!3d40.7238545!4d-73.9979167!16s%2Fg%2F11h9rwdzg_?entry=ttu
    const urlParts = url
      .replace('https://www.google.com/maps/place/', '')
      .split('/')
    $log('components:BottomSheetPlaceCreateQuo:urlParts: ', urlParts)
    if (urlParts.length > 0) {
      const fullTitle = decodeURIComponent(urlParts[0].replace(/\+/g, '%20'))
      $log('components:BottomSheetPlaceCreateQuo:fullTitle: ', fullTitle)
      const titleParts = fullTitle.split(',')
      if (titleParts[0] && titleParts[0].trim().length > 0) {
        placeTitle.value = titleParts[0].trim()
      }
      if (urlParts.length > 2) {
        const data = urlParts[2]
        // get all matches between !3d and !
        const lat = data.match(/!3d(.*?)!/g)

        // get string between !4d and !
        const lon = data.match(/!4d(.*?)!/g)

        const latLonPairs = []
        fillFromUrlLatLonPairs.value = []

        if (lat && lat.length > 0 && lon && lat.length > 0) {
          for (let i = 0; i < lat.length; i++) {
            latLonPairs.push({
              lat: lat[i].replace('!3d', '').replace('!', ''),
              lon: lon[i].replace('!4d', '').replace('!', ''),
              isSelected: false,
            })
          }
          $log(
            'components:BottomSheetPlaceCreateQuo:latLonPairs: ',
            latLonPairs
          )
          fillFromUrlLatLonPairs.value = latLonPairs
          if (latLonPairs.length === 1) {
            controllerStore.setNewPlaceLat(latLonPairs[0].lat)
            controllerStore.setNewPlaceLon(latLonPairs[0].lon)
          } else if (latLonPairs.length > 1) {
            selectPair(latLonPairs[latLonPairs.length - 1])
          }
        }
      }
    }
  } else if (url.includes('https://maps.google.com?q=')) {
    // https://maps.app.goo.gl/CVGs6Sf8Mf1LHkvp6?g_st=ic
    // https://maps.app.goo.gl/dzArULx6Ds3emVcg9?g_st=ic
    // https://maps.google.com?q=dein-auto.li+GmbH,+Peter-Kaiser-Strasse+73,+9493+Mauren&ftid=0x479b3b74375dc521:0x248766a9eaed8cfd&hl=de-DE&gl=ch&entry=gps&lucs=,47071704&g_ep=CAISBjYuNzMuMhgAINeCAyoJLDQ3MDcxNzA0QgJMSQ%3D%3D&g_st=ic
    $log('components:BottomSheetPlaceCreateQuo:url: ', url)
    const urlQuery = url.replace('https://maps.google.com', '')
    const searchParams = new URLSearchParams(urlQuery)
    const q = searchParams.get('q')
    const qParts = q.split(',')
    const newQParts = []
    qParts.forEach((p) => {
      let newP
      newP = decodeURIComponent(p.replace(/\+/g, '%20'))
      if (newP.trim() === 'Vereinigte Staaten') {
        newP = 'Vereinigte Staaten von Amerika'
      }
      $log('components:BottomSheetPlaceCreateQuo:newP: from:', newP, ':to')
      newQParts.push(newP)
    })
    $log('components:BottomSheetPlaceCreateQuo:newQParts: ', newQParts)
    const titlePart = decodeURIComponent(newQParts[0].replace(/\+/g, '%20'))
    placeTitle.value = titlePart
    // join all parts except the first one
    const lookupAddress = newQParts.slice(1).join(', ')
    // look up address with fetchNominatimAddressSearch
    $log('components:BottomSheetPlaceCreateQuo:lookupAddress: ', lookupAddress)
    fetchNominatimAddressSearch(lookupAddress).then((response) => {
      if (response && response.length > 0) {
        const firstAddress = response[0]
        $log(
          'components:BottomSheetPlaceCreateQuo:firstAddress: ',
          firstAddress
        )
        if (firstAddress) {
          controllerStore.setNewPlaceLat(firstAddress.lat)
          controllerStore.setNewPlaceLon(firstAddress.lon)
        }
      } else {
        pasteGoogleMapsLinkErrorMessage.value = 'No address found'
      }
    })
  }
}

const selectPair = (pair) => {
  $log('components:BottomSheetPlaceCreateQuo:pair: ', pair)
  fillFromUrlLatLonPairs.value.forEach((p) => {
    if (p.lat === pair.lat && p.lon === pair.lon) {
      p.isSelected = true
      controllerStore.setNewPlaceLat(pair.lat)
      controllerStore.setNewPlaceLon(pair.lon)
      p.washere = 'yes'
      $log('components:BottomSheetPlaceCreateQuo:p: ', p)
    } else {
      p.isSelected = false
      p.washere = 'no'
      $log('components:BottomSheetPlaceCreateQuo:p: ', p)
    }
  })
}

const fetchGoogleMapsHeaderLocation = async (url) => {
  const { data, error } = await useFetch('/api/url-resolver/google-maps/', {
    method: 'POST',
    body: JSON.stringify({
      url,
    }),
  })
  if (error.value) {
    console.error(
      'components:BottomSheetPlaceCreateQuo:fetchGoogleMapsHeader: error: ',
      error.value
    )
    return
  }
  if (data.value) {
    $log(
      'components:BottomSheetPlaceCreateQuo:fetchGoogleMapsHeader: ',
      data.value
    )
    return data.value
  }
}

const fetchNominatimAddressSearch = async (address) => {
  const { data, error } = await useFetch(
    'https://nominatim.openstreetmap.org/search.php',
    {
      method: 'GET',
      query: {
        q: address,
        format: 'jsonv2',
        addressdetails: 1,
        limit: 10,
      },
    }
  )
  if (error.value) {
    console.error(
      'components:BottomSheetPlaceCreateQuo:fetchNominatimAddressSearch: error: ',
      error.value
    )
    return
  }
  if (data.value) {
    $log(
      'components:BottomSheetPlaceCreateQuo:fetchNominatimAddressSearch: ',
      data.value
    )
    return data.value
  }
}

const createAnotherPlace = () => {
  controllerStore.setNewPlaceLat(null)
  controllerStore.setNewPlaceLon(null)
  controllerStore.setNewPlaceCreatedItemId(null)
  controllerStore.openBottomSheetPlaceCreate()
  controllerStore.closeBottomSheetPlaceCreateQuo()
}

const closeBottomSheet = () => {
  if (state.value === 'showPlaceCreateForm') {
    controllerStore.setNewPlaceLat(null)
    controllerStore.setNewPlaceLon(null)
    controllerStore.closeBottomSheetPlaceCreateQuo()
  } else if (state.value === 'showPlaceCreated') {
    controllerStore.setNewPlaceCreatedItemId(null)
    controllerStore.closeBottomSheetPlaceCreateQuo()
  } else {
    controllerStore.closeBottomSheetPlaceCreateQuo()
  }
}
</script>
