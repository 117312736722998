<template>
  <NuxtLink
    class="flex flex-row m-1 p-3 bg-panel-mention-card-background rounded-lg cursor-pointer"
    :to="createRoute(itemResult)"
  >
    <div class="flex-none mr-3">
      <div
        v-if="true"
        class="p-1 rounded-full"
        :class="'bg-tag-' + icon.bgColor"
      >
        <SvgIcon
          :name="icon.name"
          type="solid"
          class="h-4 w-4"
          :class="'fill-tag-' + icon.color"
        />
      </div>
    </div>
    <div class="grow flex flex-col min-w-0 space-y-1">
      <div class="text-md font-bold text-panel-accent-text">
        {{ itemTitle }}
      </div>
      <div v-if="true" class="text-sm text-panel-secondary-text">
        <span
          v-if="
            (itemResult.type && itemResult.type === 'checkins') ||
            itemResult.type === 'likes'
          "
        >
          {{ intShortDateTime(itemResult.insertedAt, locale) }} &middot;
        </span>
        <span v-for="(tag, tagIndex) in itemSubTitleTags" :key="tagIndex">
          <span v-if="tagIndex > 0"> &middot; </span>
          {{ tag.short }}
        </span>
        <span v-if="itemNewCategorySuggestion">
          <span v-if="itemSubTitleTags.length > 0"> &middot; </span>
          {{ itemNewCategorySuggestion }}
        </span>
        <span v-if="itemResult.type && itemResult.type === 'likes'">
          &middot;
          <SvgIcon
            :name="itemResult.isUnlike ? 'heart-slash' : 'heart'"
            type="solid"
            class="h-4 w-4 inline"
            :class="itemResult.isUnlike ? 'fill-gray-400' : 'fill-pink-500'"
          />
        </span>
        <span v-if="showOh && ohState">
          &middot;
          <span
            v-if="ohState === true"
            class="text-panel-oh-open-text font-medium"
            >{{ $t('openingHours.open') }}</span
          ><span v-else-if="ohState === false">{{
            $t('openingHours.closed')
          }}</span></span
        >
        <span v-if="showOh && changeShort"> &middot; {{ changeShort }}</span>
        <span v-if="address && address.city"> &middot; {{ address.city }}</span>
        <span v-if="address && address.country">
          &middot; {{ address.country }}</span
        >
      </div>
      <div
        v-if="itemResult.type && itemResult.type === 'mentions'"
        class="flex flex-col bg-gray-100 p-2 rounded text-panel-accent-text"
      >
        <div class="text-sm">{{ itemResult.bricks.note }}</div>
        <div class="text-xs text-panel-secondary-text pt-1">
          {{ intShortDateTime(itemResult.bricks.insertedAt, locale) }}
        </div>
      </div>
      <div
        v-if="itemResult.type && itemResult.type === 'guides'"
        class="space-y-3 pt-2"
      >
        <div class="text-panel-accent-text text-sm tracking-tight">
          {{ itemResult.detailsTranslations }}
        </div>
        <img
          v-if="itemMediumUrl"
          :src="itemMediumUrl"
          alt=""
          class="w-full h-44 object-cover rounded"
        />
      </div>
    </div>
  </NuxtLink>
</template>
<script setup>
const props = defineProps({
  itemResultOrItem: {
    type: Object,
    default: () => ({}),
  },
  showOh: {
    type: Boolean,
    default: false,
  },
})
// TODO: #2 should watch instead of deconstruct
// https://eslint.vuejs.org/rules/no-setup-props-destructure.html
const itemResult = ref(props.itemResultOrItem)
const showOh = computed(() => props.showOh)

const { t } = useI18n()

const { getItemNewCategorySuggestion, getItemTitle, getMediumUrl } = useItem()
const { getItemIcon, getItemSubTitleTags } = useTag()

const localePath = useLocalePath()

const { locale } = useI18n()
const { intShortDateTime } = useDateTime()

const { getItemResultAddress } = useAddress()

const createRoute = (result) => {
  let route = {}
  route = localePath({
    name: 'map-place-nanoId-slug',
    params: { nanoId: result.nanoId },
  })
  return route
}

const icon = computed(() => {
  return getItemIcon(itemResult.value, locale)
})

const itemSubTitleTags = computed(() => {
  return getItemSubTitleTags(itemResult, locale, 'ListItemCard:tags')
})

const itemTitle = computed(() => {
  return getItemTitle(itemResult.value, locale)
})

const itemNewCategorySuggestion = computed(() => {
  return getItemNewCategorySuggestion(itemResult)
})

const itemMediumUrl = computed(() => {
  return getMediumUrl(itemResult.value.media[0])
})

const address = computed(() => {
  return getItemResultAddress(itemResult.value, locale)
})

const { getChangeShort, getOpeningHoursState } = useOpeningHours()
const ohState = computed(() => getOpeningHoursState(itemResult.value, 'hours'))
const changeShort = computed(() => getChangeShort(itemResult.value, 'hours'))
</script>
